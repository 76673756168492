import React from 'react';
import CompanyLandingNavbarMultiLogo from './CompanyLandingNavbarMultiLogo';
import CompanyLandingNavbarLogo from './CompanyLandingNavbarLogo';

const CompanyLandingNavbar = ({ logoInfo, multiLogo = false }) => {
  if (multiLogo) {
    return <CompanyLandingNavbarMultiLogo logoInfo={logoInfo} />;
  }
  return <CompanyLandingNavbarLogo secondLogo={logoInfo?.fileUrl} />;
};

export default CompanyLandingNavbar;
