import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultHeader, SimpleCenteredModal } from '../../../components';
import { redirectTo } from '../../../services/utils';
import InsuranceContractDataTable from '../../../components/Contract/InsuranceContractDataTable';
import { useFetchData } from '../../../hooks';
import columns from './columns';
import { debounceIndexAdminContractItemRequest } from '../../../requests/admin/adminContractItems';
import AssociatePolicyModal from '../../../components/ContractItem/AssociatePolicyModal';
import BulkUploadContractItem from '../../../components/ContractItem/BulkUpload/BulkUploadContractItem';

const AdminContractIndex = () => {
  const modelUrl = '/admin/contracts';
  const history = useHistory();
  const customParamsRef = useRef({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [bulkUploadModalShow, setBulkUploadModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const {
    data: insurancePolicies,
    totalData: totalInsurancePolicies,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminContractItemRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los contratos. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleCheckboxChange = itemId => {
    setSelectedItems(prevSelectedEmails => {
      if (prevSelectedEmails.includes(itemId)) {
        return prevSelectedEmails.filter(e => e !== itemId);
      }
      return [...prevSelectedEmails, itemId];
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setBulkUploadModalShow(false);
    setModalBody('');
  };

  const handleShowModal = () => {
    setModalShow(true);
    setModalBody(
      <AssociatePolicyModal
        handleModalClose={handleModalClose}
        selectedItems={selectedItems}
        setMoreData={setMoreData}
        userModule="admin"
      />
    );
  };

  const handleShowBulkUploadModal = () => {
    setBulkUploadModalShow(true);
    setModalBody(
      <BulkUploadContractItem setMoreData={setMoreData} handleModalClose={handleModalClose} userModule="admin" />
    );
  };

  return (
    <DefaultHeader
      title="Contratos"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Contrato',
        onClick: () => redirectTo(history, `${modelUrl}/new`)
      }}
      secondBtn
      secondBtnOptions={{
        icon: 'add',
        name: 'Asociar a póliza',
        onClick: () => handleShowModal(),
        disabled: selectedItems.length === 0
      }}
      thirdBtn
      thirdBtnOptions={{
        isIconTooltip: true,
        icon: 'export',
        name: 'Carga masiva',
        onClick: () => handleShowBulkUploadModal()
      }}
    >
      <InsuranceContractDataTable
        fromAdmin
        tableData={insurancePolicies}
        tableDataAmount={totalInsurancePolicies}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
        columns={columns}
        selectedItems={selectedItems}
        handleCheckboxChange={handleCheckboxChange}
        userModule="admin"
      />

      <SimpleCenteredModal size="md" show={modalShow} body={modalBody} onHide={handleModalClose} />
      <SimpleCenteredModal
        size="xl"
        title="Carga masiva de bienes"
        show={bulkUploadModalShow}
        body={modalBody}
        onHide={handleModalClose}
      />
    </DefaultHeader>
  );
};

export default AdminContractIndex;
