import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { sendAlert } from '../../../actions/utils';
import { InfoCard } from '../../Utils/Cards';
import { IconBtn } from '../../Utils/Button';
import { SimpleCenteredModal } from '../../Utils/Modal';
import additionalDocumentObject from './additionalDocumentObject';
import AdditionalDocumentForm from '../AdditionalDocumentForm';
import { AbilityContext } from '../../../config/abilityContext';

const InfoCardAdditionalDocument = ({
  additionalDocuments = [],
  canAddDocuments,
  modelName,
  modelUrl,
  object,
  setMoreData,
  updateRequest,
  resourceId
}) => {
  const isEmpty = additionalDocuments.length === 0;
  const additionalDocumentInfo = additionalDocumentObject({ additionalDocuments, isEmpty });
  const ability = useAbility(AbilityContext);

  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Documentos actualizados con éxito' }));
    setMoreData(m => !m);
    setModalShow(false);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { addDocumentKeys = [], removeAttachmentIds = [] } = values[modelName];
    const sendParams = snakeCaseKeys({ [modelName]: { addDocumentKeys, removeAttachmentIds } });
    updateRequest(resourceId || object.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const canManageBeneficiary = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'Beneficiary');
  };

  const isLifeCompany = () => {
    return object.insuranceCompany?.category === 'life';
  };

  const linkToBeneficiaries = () => {
    return {
      link: true,
      value: 'Nómina',
      url: {
        pathname: `${modelUrl}/${object.id}/beneficiaries`,
        state: { originPath: `${modelUrl}/${object.id}`, insurancePolicy: object }
      }
    };
  };

  return (
    <InfoCard
      title="Documentos adicionales"
      object={additionalDocumentInfo}
      iconBtnComponent={
        canAddDocuments && (
          <IconBtn
            onClick={() => setModalShow(true)}
            icon="pen"
            className="btn edit-btn no-shadow"
            variant="primary-dark"
            size="xs"
          />
        )
      }
    >
      {isEmpty && <p className="mt-1">Sin documentos adicionales</p>}

      {canManageBeneficiary() && isLifeCompany() && <LinkComponent element={linkToBeneficiaries()} />}

      <SimpleCenteredModal
        size="xl"
        title="Agregar o eliminar documentos"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={
          <AdditionalDocumentForm
            action="edit"
            modelName={modelName}
            object={object}
            formRequest={handleUpdateRequest}
            handleCancelButton={() => setModalShow(false)}
            documentsInfo={additionalDocuments}
          />
        }
      />
    </InfoCard>
  );
};

const LinkComponent = ({ element }) => {
  return (
    <p className="list-element">
      {element.link && (
        <Link to={element.url}>
          <Button variant="link" className="p-0 ml-2">
            {element.value}
          </Button>
        </Link>
      )}
    </p>
  );
};

export default InfoCardAdditionalDocument;
