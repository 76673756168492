import {
  debounceUpdateAdminContractItemRequest,
  updateAdminContractItemRequest
} from '../../../requests/admin/adminContractItems';
import {
  debounceUpdateAdminInsuranceContractRequest,
  updateAdminInsuranceContractRequest
} from '../../../requests/admin/adminInsuranceContracts';
import { debounceUpdateContractItemRequest, updateContractItemRequest } from '../../../requests/contractItems';
import {
  debounceUpdateInsuranceContractRequest,
  updateInsuranceContractRequest
} from '../../../requests/insuranceContracts';

const basicInfoRoles = {
  broker: {
    isAdmin: false,
    name: 'Corredor',
    indexPath: '/insurance_contracts',
    updateRequest: updateInsuranceContractRequest,
    debounceUpdateRequest: debounceUpdateInsuranceContractRequest,
    updateContractItemRequest,
    debounceUpdateContractItemRequest,
    can: {
      addAdditionalDocument: true,
      addInvoiceDocument: true,
      autoSaveComment: true,
      sendDocument: true
    }
  },
  admin: {
    isAdmin: true,
    name: 'Administrador',
    indexPath: '/admin/insurance_contracts',
    updateRequest: updateAdminInsuranceContractRequest,
    debounceUpdateRequest: debounceUpdateAdminInsuranceContractRequest,
    updateContractItemRequest: updateAdminContractItemRequest,
    debounceUpdateContractItemRequest: debounceUpdateAdminContractItemRequest,
    can: {
      addAdditionalDocument: true,
      addInvoiceDocument: true,
      autoSaveComment: true,
      sendDocument: true
    }
  }
};

export default basicInfoRoles;
