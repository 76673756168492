import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useIndexFilters } from '../../hooks';
import { excludeKeys, hasActiveValues } from '../../services/utils';
import { IconBtn } from '../Utils/Button';
import { AdvancedFilters, TabFilters } from '../Utils/Filters';
import SearchDateFilter from './SearchDateFilter';

const HeaderFilter = ({
  advancedOptions,
  customParams,
  dateFilterTooltipText,
  paramName,
  remoteSelectors,
  simpleSelectors,
  orderedSelectors,
  setCustomParams = () => null,
  tabOptions,
  searchOptions
}) => {
  const { filterStatus } = useIndexFilters({});

  const [withAdvanced, setWithAdvanced] = useState(false);

  const { filters } = useSelector(state => state.utils);
  const { filterOnMount, params: filterParams } = filters;

  const handleFilterOnMount = () => {
    if (!filterOnMount) return;
    setWithAdvanced(true);
    setCustomParams(params => ({ ...params, ...filterParams }));
  };

  const handleOnChangeFilterStatus = () => {
    const tableKeys = ['dateFrom', 'dateTo', 'displayLength', 'displayStart', 'query', 'sortColumn', 'sortDirection'];
    const advancedFilters = excludeKeys(filterStatus.allFilters, tableKeys.concat('clientType'));
    const isActiveValues = hasActiveValues(advancedFilters);
    if (isActiveValues) setWithAdvanced(true);
  };

  useEffect(handleOnChangeFilterStatus, [filterStatus]);
  useEffect(handleFilterOnMount, [filterOnMount]);

  return (
    <Row className="index-filters d-flex justify-content-xl-end">
      <Col md={12} xl>
        <TabFilters
          paramName={paramName}
          optionsArray={tabOptions}
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>
      <Col md={7} xl={5}>
        <SearchDateFilter
          containerClass="ml-xl-5"
          customParams={customParams}
          setCustomParams={setCustomParams}
          dateFilterTooltipText={dateFilterTooltipText}
        />
      </Col>
      <Col md={3} xl={2} className="pt-4 d-flex justify-content-xl-end">
        <IconBtn
          icon="search"
          iconClass="ml-2"
          variant="secondary-light"
          className={`no-shadow ${withAdvanced ? '' : 'outline'} rounded-border mobile-w-100`}
          onClick={() => setWithAdvanced(!withAdvanced)}
        >
          <span className="mr-1">Avanzada</span>
        </IconBtn>
      </Col>
      <Col xs={12} className={`index-filters--secondary ${withAdvanced ? 'show' : ''}`}>
        <AdvancedFilters
          filtersOptions={advancedOptions}
          remoteSelectors={remoteSelectors}
          simpleSelectors={simpleSelectors}
          orderedSelectors={orderedSelectors}
          customParams={customParams}
          searchOptions={searchOptions}
          setCustomParams={setCustomParams}
        />
      </Col>
    </Row>
  );
};

HeaderFilter.defaultProps = {
  advancedOptions: [],
  customParams: {},
  paramName: '',
  remoteSelectors: [],
  setCustomParams: () => null,
  tabOptions: []
};

export default HeaderFilter;
