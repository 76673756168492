import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect, usePrevious } from '../../hooks';
import {
  debounceIndexAdminInsuranceItemRequest,
  indexAdminInsuranceItemRequest
} from '../../requests/admin/adminInsurancePolicies';
import { InputRemoteSelect } from '../Utils/Select';
import { debounceIndexInsuranceItemRequest, indexInsuranceItemRequest } from '../../requests/insurancePolicies';

const InsuranceItemRemoteSelector = ({ field, modelName, initialParams, tooltipText, userModule, label }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { insurancePolicyId } = getIn(values, modelName);
  const prevPolicyId = usePrevious(insurancePolicyId);

  const getIndexRequest = () => {
    const requestMap = {
      admin: insurancePolicyId ? indexAdminInsuranceItemRequest : null,
      broker: insurancePolicyId ? indexInsuranceItemRequest : null
    };
    return requestMap[userModule];
  };

  const getDebouncedIndexRequest = () => {
    const requestMap = {
      admin: debounceIndexAdminInsuranceItemRequest,
      broker: debounceIndexInsuranceItemRequest
    };
    return requestMap[userModule];
  };

  const {
    options: insuranceItems,
    selectedOption: selectedInsurancePolicy,
    fetchOptions: fetchInsuranceItems
  } = useFetchForRemoteSelect({
    indexRequest: getIndexRequest(),
    debouncedIndexRequest: getDebouncedIndexRequest(),
    value: getIn(values, `${modelName}[insuranceItemId]`),
    initialParams: {
      sort_column: 'created_at',
      sort_direction: 'asc',
      ...initialParams
    },
    nestedDependency: insurancePolicyId || '',
    paramId: insurancePolicyId
  });

  const handleSelectedItem = () => {
    const anyPolicyId = insurancePolicyId || prevPolicyId;
    const initialPrevPolicyId = insurancePolicyId && prevPolicyId === '';
    if (initialPrevPolicyId || (anyPolicyId && insurancePolicyId === prevPolicyId)) return;

    setFieldValue(`${modelName}[insuranceItemId]`, '');
  };

  useEffect(handleSelectedItem, [insurancePolicyId, prevPolicyId]);

  return (
    <InputRemoteSelect
      {...field}
      isClearable
      label={label || 'N° de ítem en póliza'}
      placeholder="Seleccionar ítem"
      defaultOptions={insuranceItems}
      value={selectedInsurancePolicy}
      request={fetchInsuranceItems}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
        setFieldValue(`${modelName}[insuranceItemNumber]`, data?.label || '');
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
      tooltipText={tooltipText}
      disabled={!insurancePolicyId}
    />
  );
};

export default InsuranceItemRemoteSelector;
