import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { BasicTextArea, FormikInput, FormikSelect, FormikSwitch } from '../../../components';
import categoryTypes from './categoryTypes';
import dictionaryTypes from './dictionaryTypes';

const AdminInsuranceCategoryForm = ({
  action,
  errors,
  onHide,
  submitVariant,
  touched,
  setFieldValue,
  insuranceCategory
}) => {
  const submitBtnText = action === 'new' ? 'Crear Ramo' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name="insuranceCategory[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCategory[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Ramo activo?" />}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="insuranceCategory[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Código"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCategory[category]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                options={categoryTypes}
                defaultValue={insuranceCategory.category}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                isClearable
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCategory[dictionaryType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Variables dinámicas"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                options={dictionaryTypes}
                defaultValue={insuranceCategory.dictionaryType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field name="insuranceCategory[validateAiPrompt]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Validación IA"
                placeholder="Ingresa los puntos a validar"
                className="p-3"
                row={6}
              />
            )}
          </Field>
        </Col>
        <Col sm={12} md={6}>
          <Field name="insuranceCategory[compareAiPrompt]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comparación IA"
                placeholder="Ingresa los puntos a comparar"
                className="p-3"
                row={6}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ insuranceCategory }) => {
  return {
    insuranceCategory: {
      ...insuranceCategory
    }
  };
};

const validationSchema = Yup.object().shape({
  insuranceCategory: Yup.object().shape({
    active: Yup.boolean(),
    category: Yup.string().required('Debe ingresar categoría'),
    code: Yup.string().nullable(),
    name: Yup.string().required('Debes ingresar el nombre')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminInsuranceCategoryForm);
