import React from 'react';

import { HeaderFilter } from '../..';
import {
  indexInsuranceCategoryRequest,
  debounceIndexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';
import {
  indexInsuranceCompanyRequest,
  debounceIndexInsuranceCompanyRequest
} from '../../../requests/insuranceCompanies';
import {
  indexInsuranceBrokerRequest,
  debounceIndexInsuranceBrokeresRequest
} from '../../../requests/admin/insuranceBrokers';
import {
  attachmentOptions,
  coverageOptions,
  insuranceContractOptions,
  insuranceContractTabsOptions,
  statusOptions
} from './InsuranceContractFiltersParams';
// import { coverageOptions, insuranceContractOptions, statusOptions } from '../attributeOptions';

const InsuranceContractFiltersHeader = ({
  customParams,
  filterHeaderComponentProps = {},
  setCustomParams,
  fromAdmin
}) => {
  const simpleSelectorOptions = [
    {
      type: 'simple',
      colSizeClass: 'col-lg',
      fieldName: 'filterStatus',
      placeholder: 'Estado',
      options: statusOptions
    },
    {
      type: 'simple',
      colSizeClass: 'col-lg',
      fieldName: 'isValid',
      placeholder: 'Contrato',
      options: insuranceContractOptions
    },
    {
      type: 'simple',
      colSizeClass: 'col-lg',
      fieldName: 'filterAttachment',
      placeholder: 'Adjuntos',
      options: attachmentOptions
    },
    {
      type: 'simple',
      colSizeClass: 'col-lg',
      fieldName: 'coverageIsValid',
      placeholder: 'Cobertura',
      options: coverageOptions
    }
  ];

  const baseSelectorOptions = [
    {
      type: 'remote',
      fieldName: 'insuranceCompanyId',
      placeholder: 'Aseguradora',
      colSizeClass: 'col-lg',
      indexRequest: indexInsuranceCompanyRequest,
      debounceRequest: debounceIndexInsuranceCompanyRequest
    },
    {
      type: 'remote',
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      colSizeClass: 'col-lg',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest
    }
  ];

  const adminSelectorOptions = [
    {
      type: 'remote',
      fieldName: 'insuranceBrokerId',
      placeholder: 'Corredor',
      colSizeClass: 'col-lg',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest
    }
  ];

  const selectorOptions = [...baseSelectorOptions, ...(fromAdmin ? adminSelectorOptions : [])];

  return (
    <HeaderFilter
      paramName="filterWithPolicy"
      tabOptions={insuranceContractTabsOptions}
      advancedOptions={filterHeaderComponentProps.advancedOptions}
      // simpleSelectors={simpleSelectorOptions}
      // remoteSelectors={selectorOptions}
      orderedSelectors={[...simpleSelectorOptions, ...selectorOptions]}
      customParams={customParams}
      setCustomParams={setCustomParams}
      dateFilterTooltipText="Busca por rangos de fecha de<br/>
                             inicio de vigencia del contrato"
    />
  );
};

export default InsuranceContractFiltersHeader;
