import React from 'react';
import { Table } from 'react-bootstrap';

const ResultAiTable = ({ columns = [], tableBody = [] }) => {
  return (
    <Table size="sm" responsive className="table__insurance-covers">
      <thead>
        <tr>
          {columns.map(({ columnName, columnStyle }, index) => (
            <th style={columnStyle} key={columnName.concat('-', index + 1)}>
              {columnName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody.map((insuranceCover, index) => (
          <tr key={`insurance-cover-${index.toString()}`}>
            {columns.map(({ value, selector, columnName }, coverIndex) => (
              <td className="align-middle" key={columnName.concat('-', coverIndex + 1)}>
                {value ?? insuranceCover[selector]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ResultAiTable;
