import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import snakeCaseKeys from 'snakecase-keys';
import * as Yup from 'yup';

import '../../../services/yupCustomMethods';
import { BasicTextArea, FormikSelect } from '../../../components';
import {
  createdCommissionFormOptions,
  createdPremiumFormOptions
} from '../../../components/PremiumCollection/attributeOptions';
import { InsurancePolicyRemoteSelector } from '../../../components/Shared';
import { buildRecordAdminPremiumCollectionRequest } from '../../../requests/admin/adminPremiumCollections';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import AdminPremiumCollectionSummaryOnCreate from './AdminPremiumCollectionSummaryOnCreate';
import AdminCommissionCollectionSummaryOnCreate from '../CommissionCollection/AdminCommissionCollectionSummaryOnCreate';

const AdminPremiumCollectionForm = ({ errors, isSubmitting, onHide, setFieldValue, touched, values, ...props }) => {
  const modelName = 'premiumCollection';
  const dispatch = useDispatch();

  const [showBuiltRecord, setShowBuiltRecord] = useState(false);

  const { handleCancelButton, premiumCollection, submitTextBtn, formType, userModule } = props;
  const { collectionType, comments, createdFrom, insurancePolicyId } = values.premiumCollection;

  const handleSuccessCommission = (builtData, sendParams) => {
    const parsedNetCompanyCommissionCalculated = builtData.netCompanyCommissionCalculated;
    const parsedTotalNetCommissionCalculated = builtData.totalNetCommissionCalculated;

    if (builtData)
      setFieldValue(modelName, {
        ...sendParams,
        ...builtData,
        parsedNetCompanyCommissionCalculated,
        parsedTotalNetCommissionCalculated
      });
    else setFieldValue(modelName, { ...premiumCollection, ...sendParams });
  };

  const handleSuccessPremium = (builtData, sendParams) => {
    const parsedTotalPremiumCalculated = builtData.totalPremiumCalculated;
    if (builtData)
      setFieldValue(modelName, {
        ...sendParams,
        ...builtData,
        parsedTotalPremiumCalculated
        // parsedTotalNetCommissionCalculated
      });
    else setFieldValue(modelName, { ...premiumCollection, ...sendParams });
  };

  const handleBuildRecordRequest = () => {
    const sendParams = { collectionType, createdFrom, insurancePolicyId, comments };
    buildRecordAdminPremiumCollectionRequest({
      dispatch,
      formData: true,
      params: snakeCaseKeys({ premiumCollection: sendParams }),
      successCallback: response => {
        const builtData = camelCaseEmptyStringRecursive(response.data);
        if (formType === 'premium') handleSuccessPremium(builtData, sendParams);
        else handleSuccessCommission(builtData, sendParams);

        setShowBuiltRecord(builtData);
      }
    });
  };

  const handleOnChangeInsurancePolicyId = () => {
    if (createdFrom && insurancePolicyId) handleBuildRecordRequest();
    else setShowBuiltRecord(false);
  };

  const getCadenceType = () => {
    if (createdFrom === 'debt') {
      return 'unique';
    }
    if (createdFrom === 'monthly_cadence') {
      return 'monthly';
    }

    return '';
  };

  const initialParams = {
    cadence_type: getCadenceType(),
    with_debt: createdFrom === 'debt' ? true : ''
  };

  useEffect(handleOnChangeInsurancePolicyId, [insurancePolicyId]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name={`${modelName}[createdFrom]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                isDisabled={formType === 'commission'}
                label="Origen"
                placeholder="Seleccionar origen"
                options={formType === 'premium' ? createdPremiumFormOptions : createdCommissionFormOptions}
                defaultValue={premiumCollection.createdFrom}
                onChange={option => setFieldValue(field.name, option?.value || '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[insurancePolicyId]`}>
            {({ field }) => (
              <InsurancePolicyRemoteSelector
                field={field}
                modelName={modelName}
                initialParams={initialParams}
                nestedDependency={createdFrom}
                userModule={userModule}
              />
            )}
          </Field>
        </Col>
      </Row>

      {showBuiltRecord && formType === 'premium' && (
        <AdminPremiumCollectionSummaryOnCreate
          modelName={modelName}
          premiumCollection={premiumCollection}
          setShowBuiltRecord={setShowBuiltRecord}
        />
      )}

      {showBuiltRecord && formType === 'commission' && (
        <AdminCommissionCollectionSummaryOnCreate
          modelName={modelName}
          premiumCollection={premiumCollection}
          setShowBuiltRecord={setShowBuiltRecord}
        />
      )}

      <Row>
        <Col>
          <Field name={`${modelName}[comments]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                rows={2}
                maxLength={1000}
                label="Comentarios"
                placeholder="Ingresa tu comentario..."
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex flex-row-reverse align-items-center">
        <Col md={4} className="mt-4">
          <Button block type="submit" variant="submit" onClick={onHide} disabled={isSubmitting}>
            {submitTextBtn}
          </Button>
        </Col>
        <Col md={3} className="mt-4">
          <Button block variant="cancel" className="mr-3" onClick={handleCancelButton}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ premiumCollection }) => ({ premiumCollection });

const premiumValidationSchema = Yup.object().shape({
  premiumCollection: Yup.object().shape({
    collectionDate: Yup.date()
      .required('Debes ingresar una fecha de cobranza')
      .formatdate(),
    collectionType: Yup.string().required('Debes seleccionar un tipo de cobranza'),
    comments: Yup.string().nullable(),
    createdFrom: Yup.string().required('Debes seleccionar un origen de creación'),
    // installmentNumber: Yup.string().when('createdFrom', {
    //   is: value => ['unique_cadence', 'debt'].includes(value),
    //   then: Yup.string().required('Debes ingresar un número de cuota'),
    //   otherwise: Yup.string().nullable()
    // }),
    insurancePolicyId: Yup.string().required('Debes seleccionar una póliza'),
    status: Yup.string().required('Debes seleccionar un estado'),
    totalPremiumCalculated: Yup.string().required('Debes ingresar una prima calculada total bruta')
  })
});

const commissionValidationSchema = Yup.object().shape({
  premiumCollection: Yup.object().shape({
    collectionDate: Yup.date()
      .required('Debes ingresar una fecha de cobranza')
      .formatdate(),
    collectionType: Yup.string().required('Debes seleccionar un tipo de cobranza'),
    comments: Yup.string().nullable(),
    createdFrom: Yup.string().required('Debes seleccionar un origen de creación'),
    insurancePolicyId: Yup.string().required('Debes seleccionar una póliza'),
    status: Yup.string().required('Debes seleccionar un estado'),
    totalNetCommissionCalculated: Yup.string().required('Debes ingresar una comisión calculada total neta'),
    netCompanyCommissionCalculated: Yup.string().required('Debes ingresar una comisión empresa calculada neta')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

const selectValidationSchema = props => {
  return props.formType === 'premium' ? premiumValidationSchema : commissionValidationSchema;
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema: selectValidationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminPremiumCollectionForm);
