import ApiService from '../../services/apiService';

export default function publicOticLandingForm({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) {
  ApiService.request('post', '/public/landings/otic_form', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
}
