import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { UploadFile } from '../Utils/Upload';

const AttachFile = ({ abbr, attribute, disabled, fileAccept, label, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const savedFile = getIn(values, `${modelName}[${attribute}Info]`);
  const newFile = getIn(values, `${modelName}[${attribute}]`);

  const handleFileUrl = file => {
    if (!file) return '';
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  };

  return (
    <Col lg={6} className="mb-3">
      <Field name={`${modelName}[${attribute}]`}>
        {({ field }) => (
          <UploadFile
            {...field}
            abbr={abbr}
            fileAccept={fileAccept}
            label={label}
            filename={newFile?.name || savedFile?.filename}
            fileUrl={savedFile?.fileUrl || handleFileUrl(newFile)}
            onChange={file => setFieldValue(field.name, file)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            disabled={disabled}
          />
        )}
      </Field>
    </Col>
  );
};

export default AttachFile;
