import React, { useState } from 'react';
import { Field, Form, getIn, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row, Form as Bform } from 'react-bootstrap';

import FormikInput from '../../Utils/Input/FormikInput';
import FormikDatePicker from '../../Utils/Input/FormikDatePicker';
import { SimpleCenteredModal } from '../../Utils/Modal';
import StudentsModalBody from './Form/StudentsModalBody';

const OticLandingForm = ({ company }) => {
  const { errors, touched, values, isValid, isSubmitting } = useFormikContext();
  const [modalShow, setModalShow] = useState(false);
  const { landingPrimaryColor, landingSecondaryColor } = company;

  const renderStudentsError = () => {
    const fieldErrors = getIn(errors, 'students');
    const isTouched = getIn(touched, 'students');
    const studentsValues = getIn(values, 'students');

    if (!isTouched || !fieldErrors) {
      return null;
    }

    if (Array.isArray(fieldErrors)) {
      return (
        <>
          {fieldErrors.map((error, index) => {
            if (!error) return null;
            const studentObject = studentsValues[index];

            const errorMessages = Object.values(error)
              .filter(Boolean)
              .join(', ');

            return (
              <Bform.Text key={studentObject?.nationalIdentification || index} className="text-danger">
                <strong>{studentObject?.fullName || index + 1}: </strong> {errorMessages}
              </Bform.Text>
            );
          })}
        </>
      );
    }

    return <Bform.Text className="text-danger">{fieldErrors}</Bform.Text>;
  };

  const handleModalClose = () => setModalShow(false);
  return (
    <div className="landing-form landing-form-company" style={{ '--inline-secondary-color': landingSecondaryColor }}>
      <p className="landing-form-company__title">
        Completa el siguiente formulario para registrar nuevos cursos y alumnos, y agregarlos a tu póliza de seguros.
        {String.fromCodePoint('0x26A1')}
      </p>
      <div
        className="landing-form-company__otic_container"
        style={{ '--inline-primary-color': landingPrimaryColor, alignItems: 'unset' }}
      >
        <Form className="m-0">
          <div className="mb-5">
            <div className="mb-4">
              <p className="row-title mb-2">Información del curso</p>
            </div>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <Field name="courseName">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      required
                      placeholder="Nombre completo del curso"
                      label="Nombre"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Field name="courseCode">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      required
                      placeholder="Código del curso"
                      label="Código"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <Field name="startDate">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      required
                      abbr
                      label="Fecha de inicio"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      tooltipText="Corresponde a la fecha en la que los alumnos comienzan el
curso."
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Field name="endDate">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      abbr
                      tooltipText="Corresponde a la fecha en la que los alumnos terminan el
curso."
                      label="Fecha de término"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </div>
          <div className="mb-5">
            <div className="mb-4">
              <p className="row-title mb-2">Listado de alumnos</p>
            </div>
            <Row>
              <Col xs={12}>
                <Row className="align-items-center pl-4">
                  {values?.students?.length > 0 && (
                    <ul style={{ listStyle: 'none' }}>
                      {values.students.map(student => (
                        <li key={`${student.nationalIdentification}-${student.fullName}`}>
                          <strong>Nombre: </strong> {student.fullName} <strong>Rut: </strong>
                          {student.nationalIdentification}
                        </li>
                      ))}
                    </ul>
                  )}
                  {values.students.length <= 0 && <p>Debes cargar al menos 1 alumno</p>}
                  <Button variant="primary-light" className="ml-5" onClick={() => setModalShow(true)}>
                    Cargar
                  </Button>
                </Row>
                <SimpleCenteredModal
                  size="md"
                  show={modalShow}
                  title="Listado de alumnos"
                  body={() => StudentsModalBody({ handleModalClose })}
                  onHide={handleModalClose}
                />
                {renderStudentsError()}
              </Col>
            </Row>
          </div>
          <Row className="d-flex justify-content-end align-items-center">
            <Col xs={4} sm={4}>
              <Button block type="submit" variant="submit" className="no-shadow" disabled={!isValid || isSubmitting}>
                Crear certificado
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const setInitialValues = ({ oticLanding }) => {
  return {
    ...oticLanding
  };
};

const validationSchema = Yup.object().shape({
  courseName: Yup.string().required('Debes ingresar un nombre para curso'),
  courseCode: Yup.string().required('Debes ingresar un código de curso'),
  startDate: Yup.date()
    .required('Debes ingresar una fecha de inicio')
    .test('is-before-end-date', 'La fecha de inicio debe ser menor que la fecha de término', function(value) {
      const { endDate } = this.parent;
      return !endDate || new Date(value) < new Date(endDate);
    }),
  endDate: Yup.date()
    .required('Debes ingresar una fecha de término')
    .test('is-after-start-date', 'La fecha de término debe ser mayor que la fecha de inicio', function(value) {
      const { startDate } = this.parent;
      return !startDate || new Date(value) > new Date(startDate);
    }),
  students: Yup.array()
    .of(
      Yup.object().shape({
        fullName: Yup.string().required('Debes ingresar el nombre del alumno'),
        nationalIdentification: Yup.string()
          .required('Debes ingresar el RUT del alumno')
          .rut('El RUT no es válido')
      })
    )
    .min(1, 'Debes ingresar al menos 1 alumno')
    .required('Debes ingresar al menos 1 alumno')
});

const handleSubmit = (values, { setSubmitting, props }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: true
})(OticLandingForm);
