import React from 'react';
import ContractItems from './ContractItems';

const ContractFormSecondTab = ({ modelName, userModule }) => {
  return (
    <section className="form-section mb-5 w-100">
      <p className="section-title">Información de los bienes</p>
      <ContractItems modelName={modelName} userModule={userModule} />
    </section>
  );
};

export default ContractFormSecondTab;
