import React, { useState } from 'react';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { checkAdminThreadAi } from '../../../requests/admin/lead';
import { ModalBodyIcon } from '../../Shared';
import { sendAlert } from '../../../actions/utils';
import { readAdminInsurancePolicyAiRequest } from '../../../requests/admin/adminInsurancePolicies';
import { BasicTextArea } from '../../Utils/Input';
import { generatePrompt } from './prompts';
import { camelCaseEmptyStringRecursive, isObject } from '../../../services/utils';
import ResultAiTable from './ResultAiTable';
import IconGG from '../../Utils/Icon';

const ValidateContentAiModal = ({
  handleModalClose,
  validateAiPrompt,
  policyFile,
  insuranceCategoryName,
  attachmentId
}) => {
  const variant = 'submit';
  const iconVariant = 'success';
  const icon = 'check-o';
  const dispatch = useDispatch();
  const [resultAi, setResultAi] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const RETRY_DELAY = 10000;

  const modalTitle = 'Validar póliza ✨';

  const submitButtonText = () => {
    return loading ? (
      <>
        Analizando...
        <span role="img" aria-label="sparkles">
          ✨
        </span>
        <Spinner animation="border" variant="primary" className="ml-2" />
      </>
    ) : (
      'Leer ✨'
    );
  };

  const handleCompletedStatus = message => {
    const jsonMatch = message.match(/```json\n([\s\S]*?)\n```/);
    // setResultAi(message);
    setLoading(false);
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        setResultAi(parsedObject);
      } catch (error) {
        dispatch(sendAlert({ kind: 'error', message: 'Error al parsear JSON' }));
      }
    }
  };

  const handleFailedStatus = message => {
    setResultAi(message);
    setLoading(false);
    // dispatch(sendAlert({ kind: 'error', message }));
    setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
  };

  const handleCheckThread = ({ theadId, runId, attempt = 1 }) => {
    const endpointRequest = checkAdminThreadAi;
    const sendParams = { thread_id: theadId, run_id: runId };
    endpointRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => {
        const { message, status } = response.data;

        if (status === 'completed') {
          handleCompletedStatus(message);
        } else if (status === 'failed') {
          handleFailedStatus(message);
        } else {
          setTimeout(() => {
            handleCheckThread({ theadId, runId, attempt: attempt + 1 });
          }, RETRY_DELAY);
        }
      }
    });
  };

  const handleReadSuccessRequest = response => {
    if (!response.data) {
      setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      setLoading(false);
      return;
    }
    const { thread_id: theadId, run_id: runId } = response.data;
    setTimeout(() => {
      handleCheckThread({ theadId, runId });
    }, RETRY_DELAY);
  };

  const completePrompt = () => {
    return generatePrompt({ insuranceCategoryName, validateAiPrompt, additionalInstruction });
  };

  const handleReadPolicyAIRequest = () => {
    setLoading(true);
    const sendParams = {
      prompt: completePrompt(),
      file: isObject(policyFile) ? policyFile : null,
      attachment_id: isObject(policyFile) ? null : attachmentId
    };

    readAdminInsurancePolicyAiRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: response => handleReadSuccessRequest(response),
      failureCallback: () => {
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
        setLoading(false);
      }
    });
  };

  const disabledButton = () => {
    return loading;
  };

  const generateColumns = () => [
    { columnName: 'Puntos', columnStyle: { width: '200px' }, selector: 'title' },
    { columnName: 'Resultado', columnStyle: { width: '200px' }, selector: 'result' }
  ];

  const generateTableBody = items => {
    return items.map(item => {
      if (item.explanation) {
        return {
          ...item,
          result: `${item.result} - ${item.explanation}`
        };
      }
      return item;
    });
  };

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        size="xl"
        iconVariant={iconVariant}
        content={modalTitle}
        confirmText={false}
        subContent={
          <>
            <Row>
              {!resultAi && (
                <>
                  {errorMessage && (
                    <Col md={12} className="d-flex align-items-center justify-content-center">
                      <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                        <IconGG className="mr-2" icon="danger" size="sm" />
                        <p className="detail font-weight-bold m-0">{errorMessage}</p>
                      </Alert>
                    </Col>
                  )}

                  <p>Puntos a validar:</p>

                  <Col md={12}>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{validateAiPrompt}</p>
                  </Col>

                  <Col md={12}>
                    <BasicTextArea
                      label="Instrucción adicional"
                      onChange={e => {
                        setAdditionalInstruction(e.target.value);
                      }}
                      placeholder="Instrucción adicional"
                      value={additionalInstruction}
                      row={3}
                    />
                  </Col>
                </>
              )}
            </Row>

            {resultAi && (
              <div>
                <ResultAiTable columns={generateColumns()} tableBody={generateTableBody(resultAi.items)} />
              </div>
            )}
          </>
        }
        formContent={
          <Row className="justify-content-center">
            <Col md={6} className="mt-4">
              <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                Cerrar
              </Button>
            </Col>
            <Col md={6} className="mt-4">
              <Button
                block
                type="submit"
                variant={variant}
                className="no-shadow"
                onClick={handleReadPolicyAIRequest}
                disabled={disabledButton()}
              >
                {submitButtonText()}
              </Button>
            </Col>
          </Row>
        }
        highlightedText=""
        highlightedVariant="white"
        handleModalClose={handleModalClose}
        submitVariant={variant}
      />
    </>
  );
};

export default ValidateContentAiModal;
