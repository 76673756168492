import moment from 'moment';
import basicContractItem from '../../../components/Contract/ContractForm/basicContractItem';
import { addDaysToDate } from '../../../services/utils';

const currentDate = moment().format('DD/MM/YYYY');
const nextYear = addDaysToDate(moment(), 365);

const adminBasicContract = {
  accountId: '',
  accountName: '',
  additionalDocumentsInfo: [],
  comments: '',
  contract: '',
  contractInfo: {},
  contractNumber: '',
  description: '',
  disableEndDate: false,
  executiveContract: '',
  insuranceBrokerId: '',
  invoicesInfo: [],
  signatureDate: currentDate,
  status: '',
  translatedStatus: '',
  validityEnd: nextYear,
  validityStart: currentDate,
  contractItemsAttributes: [basicContractItem]
};

export default adminBasicContract;
