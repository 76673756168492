import { defineAbility, AbilityBuilder } from '@casl/ability';
import jwt from 'jwt-decode';

const justAdminAbility = (can, cannot) => {
  console.log('justAdminAbility');
  can('manage', 'Admin');
  can('manage', 'Lead');
  cannot('manage', 'Account');
  cannot('manage', 'Platform');
};

const adminBrokerAbility = can => {
  console.log('adminBrokerAbility');
  can('create', 'PlatformUser');
  can('manage', 'Account');
  can('manage', 'Admin');
  can('manage', 'Lead');
  can('manage', 'LeadQuotation');
  can('manage', 'PolicyReport');
  can('manage', 'LeadReport');
  can('manage', 'DebtReport');
  can('manage', 'AccountReport');
  can('manage', 'BeneficiaryReport');
  can('manage', 'Platform');
  can('manage', 'InsurancePolicy');
};

const alfaBrokerAbility = (can, cannot) => {
  console.log('alfaBrokerAbility');
  can('create', 'PlatformUser');
  can('manage', 'Account');
  can('manage', 'Lead');
  can('manage', 'Platform');
  can('manage', 'PolicyReport');
  can('manage', 'LeadReport');
  can('manage', 'DebtReport');
  can('manage', 'AccountReport');
  can('manage', 'BeneficiaryReport');
  cannot('manage', 'Admin');
};

const sigmaBrokerAbility = (can, cannot) => {
  console.log('sigmaBrokerAbility');
  can('create', 'PlatformUser');
  can('manage', 'Account');
  can('manage', 'Lead');
  can('manage', 'Platform');
  can('manage', 'InsurancePolicy');
  can('manage', 'InsuranceEndorsement');
  can('manage', 'Beneficiary');
  can('manage', 'PolicyReport');
  can('manage', 'DebtReport');
  can('manage', 'AccountReport');
  can('manage', 'BeneficiaryReport');
  cannot('manage', 'Admin');
};

const basicAbility = can => {
  console.log('basicAbility');
  can('read', 'all');
};

const gammaInsuredAbility = can => {
  console.log('gammaInsuredAbility');
  can('manage', 'Account');
  can('manage', 'InsurancePolicy');
  can('manage', 'InsuranceEndorsement');
};

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');
  if (payload) {
    // console.log('jwtPayload: ', jwt(payload));
    const { roles, broker_type: brokerType, insured_type: insuredType } = jwt(payload);
    const isAdmin = roles.includes('admin') || roles.includes('admin_standard');

    if (isAdmin && !brokerType) {
      justAdminAbility(can, cannot);
    } else if (isAdmin && roles.includes('broker')) {
      adminBrokerAbility(can, cannot);
    } else if (roles.includes('broker') && brokerType === 'sigma') {
      sigmaBrokerAbility(can, cannot);
    } else if (roles.includes('broker') && brokerType === 'alfa') {
      alfaBrokerAbility(can, cannot);
    } else if (roles.includes('insured') && insuredType === 'gamma') {
      gammaInsuredAbility(can, cannot);
    } else {
      basicAbility(can, cannot);
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = ability => {
  const { can, cannot, rules } = new AbilityBuilder();
  setUpAbility(can, cannot);
  ability.update(rules);
};
