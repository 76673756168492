import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { InfoCardGeneral, InfoCardContractItems } from '../InfoCard';

const ContractFormSummary = ({ modelName }) => {
  const { values } = useFormikContext();
  const contract = getIn(values, modelName);

  return (
    <>
      <p className="section-title">Resumen</p>
      <div className="insurance-policy-show-layout summary-main">
        <div className="general-information">
          <InfoCardGeneral contract={contract} />
        </div>
        <div className="details-information">
          <InfoCardContractItems contract={contract} />
        </div>
      </div>
    </>
  );
};

export default ContractFormSummary;
