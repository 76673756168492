import React from 'react';

import { excludeKeys } from '../../../services/utils';
import generalObject from './generalObject';
import { InfoCard } from '../../Utils/Cards';

const InfoCardGeneral = ({ contractItem, userModule, hideAttributes }) => {
  let generalInfo = generalObject({ contractItem, userModule });
  generalInfo = excludeKeys(generalInfo, hideAttributes);

  return <InfoCard title="Información del contrato" object={generalInfo} />;
};

export default InfoCardGeneral;
