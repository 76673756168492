import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Form, Field, getIn } from 'formik';
import { isAfter, isEqual } from 'date-fns';
import moment from 'moment';
import * as Yup from 'yup';

import { FormikDatePicker, BasicTextArea } from '../Utils/Input';
import TaskCompleteCheckbox from './TaskCompleteCheckbox';
import '../../services/yupCustomMethods';

const TaskForm = ({ errors, setFieldValue, setMoreData, task, touched, ...props }) => {
  const modelName = 'task';
  const { done: isTaskDone } = task;
  const { action, fromDataTable, handleDeleteTaskRequest, fromAdmin } = props;

  const isEdit = action === 'edit';
  const buttonMessage = action === 'new' ? 'Crear tarea' : 'Guardar cambios';

  return (
    <Form>
      <Row>
        <Col lg={6}>
          <Field name={`${modelName}[dueDateDate]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha tarea"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isEdit && isTaskDone}
              />
            )}
          </Field>
        </Col>
        <Col lg={6}>
          <Field name={`${modelName}[dueDateTime]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                optional
                onlyTimeSelector
                label="Hora"
                placeholder="00:00"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isEdit && isTaskDone}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name={`${modelName}[description]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Descripción tarea"
                placeholder="Escribe tu tarea aquí..."
                rows={3}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isEdit && isTaskDone}
              />
            )}
          </Field>
        </Col>
      </Row>
      {isEdit && !fromDataTable && (
        <Row className="d-flex flex-row-reverse m-0 mt-2">
          <TaskCompleteCheckbox fromAdmin={fromAdmin} task={task} setMoreData={setMoreData} label="Dar por terminada" />
        </Row>
      )}

      <Row className={`d-flex flex-row-reverse m-0 mt-5 ${isEdit ? 'justify-content-between' : ''}`}>
        {!isTaskDone && (
          <Button type="submit" variant="submit" size="lg">
            {buttonMessage}
          </Button>
        )}
        {isEdit && (
          <Button variant="danger" size="lg mx-3" onClick={handleDeleteTaskRequest}>
            Eliminar recordatorio
          </Button>
        )}
      </Row>
    </Form>
  );
};

const setInitialValues = ({ task }) => {
  const { id, description, dueDate } = task;
  if (!id) return { task };
  const myDate = moment(dueDate, 'DD-MM-YYYY hh:mm').toDate();
  return {
    task: {
      id,
      description,
      dueDateDate: myDate,
      dueDateTime: myDate
    }
  };
};

const validationSchema = Yup.object().shape({
  task: Yup.object().shape({
    dueDateDate: Yup.date()
      .required('Debes ingresar una fecha')
      .test('check date validity', 'La fecha de tarea debe ser en el futuro', dueDateDate => {
        const dueDate = new Date(dueDateDate);
        const today = new Date().setHours(0, 0, 0, 0);
        return isAfter(dueDate, today) || isEqual(dueDate, today);
      }),
    dueDateTime: Yup.string().nullable(),
    description: Yup.string().required('Debes ingresar una descripción del tarea')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(TaskForm);
