import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { BasicTextArea } from '../../../Utils/Input';

const StudentsModalBody = ({ handleModalClose }) => {
  const { errors, touched, setFieldValue, values, setFieldTouched } = useFormikContext();
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  const onChangeTextArea = ({ inputValue }) => {
    const formattedValue = inputValue.replace(/;/g, '\t');
    setFieldValue('studentsString', formattedValue);
  };

  const handleOnSave = () => {
    if (!values?.studentsString) {
      setFieldValue('students', []);
      setFieldTouched('students', true);
      handleModalClose();
      return;
    }
    const rows = values?.studentsString?.split('\n').filter(word => word);
    const students = rows.reduce((acc, row) => {
      const [fullName, nationalIdentification] = row.split('\t');
      const newStudent = { fullName: fullName.trim(), nationalIdentification: nationalIdentification.trim() };
      return [...acc, newStudent];
    }, []);
    setFieldValue('students', students);
    setFieldTouched('students', true);
    handleModalClose();
  };

  return (
    <>
      <p>Para cargar el listado de alumnos del curso:</p>
      <ul>
        <li className="ml-4">
          Ingresa un alumno por línea con los siguientes datos:{' '}
          <span className={commonClasses}>Nombre completo; RUT</span>
        </li>
        <li className="ml-4">
          Copia los datos desde una planilla de datos (documento Excel, Google Sheets o similar) o separa cada dato del
          listado utilizando <span className={commonClasses}>;</span>
        </li>
        <li className="ml-4">No incluyas encabezados, ni mas columnas con otros datos.</li>
      </ul>
      <Field name="studentsString">
        {({ field }) => (
          <BasicTextArea
            {...field}
            row={6}
            placeholder="Nombre completo; RUT"
            onChange={({ target }) => onChangeTextArea({ fieldName: 'students', inputValue: target.value })}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            className="p-3 textarea-tab-size"
          />
        )}
      </Field>
      <Row className="flex-row-reverse">
        <Col xs={4}>
          <Button variant="primary-light" type="submit" onClick={handleOnSave}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default StudentsModalBody;
