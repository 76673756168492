import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexContractItemRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/contract_items', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateContractItemRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/contract_items/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const showContractItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/contract_items/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteContractItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/contract_items/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const buklUploadContractItemRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', `/contract_items/bulk_upload`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexContractItemRequest = AwesomeDebouncePromise(indexContractItemRequest, 1000);

export const debounceUpdateContractItemRequest = AwesomeDebouncePromise(updateContractItemRequest, 1000);
