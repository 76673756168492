import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { InfoCard } from '../../Utils/Cards';
import { IconBtn } from '../../Utils/Button';
import { SimpleCenteredModal } from '../../Utils/Modal';
import additionalDocumentObject from './additionalDocumentObject';
import AdditionalDocumentForm from '../AdditionalDocumentForm';

const InfoCardInvoiceDocument = ({
  additionalDocuments = [],
  canAddDocuments,
  modelName,
  object,
  setMoreData,
  updateRequest,
  resourceId
}) => {
  const isEmpty = additionalDocuments.length === 0;
  const additionalDocumentInfo = additionalDocumentObject({ additionalDocuments, isEmpty });

  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Documentos actualizados con éxito' }));
    setMoreData(m => !m);
    setModalShow(false);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { addInvoiceDocumentKeys = [], removeInvoiceAttachmentIds = [] } = values[modelName];
    const sendParams = snakeCaseKeys({ [modelName]: { addInvoiceDocumentKeys, removeInvoiceAttachmentIds } });
    updateRequest(resourceId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <InfoCard
      title="Facturas"
      object={additionalDocumentInfo}
      iconBtnComponent={
        canAddDocuments && (
          <IconBtn
            onClick={() => setModalShow(true)}
            icon="pen"
            className="btn edit-btn no-shadow"
            variant="primary-dark"
            size="xs"
          />
        )
      }
    >
      {isEmpty && <p className="mt-1">Sin documentos adicionales</p>}

      <SimpleCenteredModal
        size="xl"
        title="Agregar o eliminar facturas"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={
          <AdditionalDocumentForm
            action="edit"
            modelName={modelName}
            object={object}
            formRequest={handleUpdateRequest}
            handleCancelButton={() => setModalShow(false)}
            addKeyName="addInvoiceDocumentKeys"
            removeKeyName="removeInvoiceAttachmentIds"
            documentsInfo={additionalDocuments}
          />
        }
      />
    </InfoCard>
  );
};

export default InfoCardInvoiceDocument;
