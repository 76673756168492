import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { createAdminPremiumCollectionRequest } from '../../../requests/admin/adminPremiumCollections';
import AdminPremiumCollectionForm from '../PremiumCollection/AdminPremiumCollectionForm';
// import AdminPremiumCollectionForm from './AdminPremiumCollectionForm';

const basicCommissionCollection = {
  id: '',
  collectionDate: '',
  collectionType: 'commission',
  comments: '',
  createdFrom: 'unique_cadence',
  installmentNumber: '',
  insurancePolicyId: '',
  status: '',
  totalNetPremiumCalculated: ''
};

const AdminCommissionCollectionNew = ({ setModalShow, setMoreData, handleModalClose }) => {
  const modelUrl = '/admin/commissions_collections';
  const dispatch = useDispatch();

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    createAdminPremiumCollectionRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => dispatch(sendAlert({ kind: 'success', message: 'Comisión creada con éxito' })),
      callback: () => {
        setSubmitting(false);
        setModalShow(false);
        setMoreData(m => !m);
      }
    });
  };

  return (
    <AdminPremiumCollectionForm
      modelUrl={modelUrl}
      premiumCollection={basicCommissionCollection}
      formRequest={handleCreateRequest}
      handleCancelButton={handleModalClose}
      submitTextBtn="Crear comisión"
      formType="commission"
      userModule="admin"
    />
  );
};

export default AdminCommissionCollectionNew;
