import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { FormHeader, SimpleCenteredModal } from '../../../components';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';
import adminBasicContractItem from './adminBasicContractItem';
import ContractItemInfo from '../../../components/ContractItem/ContractItemInfo';
import { showAdminContractItemRequest } from '../../../requests/admin/adminContractItems';
import AssociatePolicyModal from '../../../components/ContractItem/AssociatePolicyModal';

const AdminContractShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/contracts';
  const dispatch = useDispatch();
  const history = useHistory();

  const [insuranceContractItem, setInsuranceContractItem] = useState(adminBasicContractItem);
  const [isFetching, setIsFetching] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');

  const handleBreadcrumb = [
    { key: 1, name: 'Contratos', href: modelUrl },
    { key: 2, name: 'Detalle de bien' }
  ];

  const { itemNumber, insuranceContractInfo, insuranceContractId } = insuranceContractItem;

  const handleSuccessShow = response => {
    setInsuranceContractItem(camelCaseEmptyStringRecursive(response.data));
  };

  const handleShowContractItem = () => {
    setIsFetching(true);
    showAdminContractItemRequest(id, {
      dispatch,
      successCallback: handleSuccessShow,
      callback: () => setIsFetching(false)
    });
  };

  const goToEdit = () => {
    redirectTo(history, `${modelUrl}/${insuranceContractId}/edit`, {
      contractItemId: id
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleShowModal = () => {
    setModalShow(true);
    setModalBody(
      <AssociatePolicyModal
        handleModalClose={handleModalClose}
        selectedItems={[id]}
        setMoreData={setMoreData}
        userModule="admin"
      />
    );
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowContractItem, [moreData, id]);

  if (isFetching)
    return (
      <div
        className="containerSpinnerLoad"
        style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={`Contrato N° ${insuranceContractInfo.contractNumber} - Bien N° ${itemNumber} - Cuenta ${insuranceContractInfo.accountName}`}
      headerBtn
      headerBtnOptions={{
        isIconTooltip: true,
        className: 'edit-btn',
        variant: 'primary-dark',
        icon: 'pen',
        name: 'Editar Bien',
        onClick: () => goToEdit()
      }}
      secondBtn
      secondBtnOptions={{
        icon: 'add',
        name: 'Asociar a póliza',
        onClick: () => handleShowModal()
      }}
      formComponent={
        <>
          <ContractItemInfo
            userModule="admin"
            modelUrl={modelUrl}
            contractItem={insuranceContractItem}
            setMoreData={setMoreData}
          />

          <SimpleCenteredModal size="md" show={modalShow} body={modalBody} onHide={handleModalClose} />
        </>
      }
    />
  );
};

export default AdminContractShow;
