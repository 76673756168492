import React from 'react';
import { InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { ButtonTooltip } from '../Tooltips';

const DataTableNumberFormat = ({
  id,
  attribute,
  defaultValue,
  tooltipHtml,
  tooltipText,
  updateDataTable,
  updateDataTableItem,
  updateRequest,
  value,
  differenceExceeded,
  ...props
}) => {
  return (
    <InputGroup className="datatable-input">
      <NumberFormat
        allowEmptyFormatting
        allowNegative
        className={`form-control ${differenceExceeded ? 'background-yellow' : ''}`}
        decimalScale={2}
        decimalSeparator=","
        thousandSeparator="."
        defaultValue={defaultValue}
        onValueChange={amount =>
          updateRequest({ id, attribute, value: amount.floatValue || '', updateDataTable, updateDataTableItem })
        }
        {...props}
      />
      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
    </InputGroup>
  );
};

export default DataTableNumberFormat;
