import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { WebLayout } from '../../components';
import { LandingCenteredContent, LandingFooter } from '../../components/Landing/Shared';
import OticLandingForm from '../../components/Landing/OticLanding/OticLandingForm';
import { basicOticLanding, oticCompanyPlacerHolder } from '../../components/Landing/OticLanding/Form/basicOticLanding';
import publicOticLandingForm from '../../requests/public/publicLanding';
import { CompanyLandingMain } from '../../components/Landing/CompanyLanding';
import SuccessRequestModal from '../../components/Landing/OticLanding/SuccessRequestModal';

const OticLanding = () => {
  const { settings } = useSelector(state => state.utils);
  const dispatch = useDispatch();
  const company = { ...oticCompanyPlacerHolder };
  const { landingPrimaryColor, landingSecondaryColor, logoInfo, multiLogo, textOnScroll, newTab } = company;
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCreateCertificate = (values, setSubmitting) => {
    const sendParams = snakecaseKeys(values);
    publicOticLandingForm({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => {
        setShowSuccessModal(true);
      },
      callback: () => {
        setSubmitting(false);
      }
    });
  };
  return (
    <WebLayout
      type="company"
      extraSettings={{ landingPrimaryColor, landingSecondaryColor, logoInfo, multiLogo, textOnScroll, newTab }}
    >
      <CompanyLandingMain company={company} />
      <OticLandingForm oticLanding={basicOticLanding} formRequest={handleCreateCertificate} company={company} />
      <LandingCenteredContent
        bgVariant="bg-white"
        title={
          <React.Fragment>
            Recuerda que puedes ingresar a tu portal del Asegurado en{' '}
            <a href="/insured/login" target="_blank" className="text-aqua">
              NICO
            </a>
          </React.Fragment>
        }
        text="Aquí encontrarás todas tus pólizas y podrás solicitar nuestro apoyo para lo que sea que necesites"
      />
      <LandingFooter settings={settings} extraSettings={{ landingPrimaryColor, landingSecondaryColor, textOnScroll }} />
      <SuccessRequestModal modalShow={showSuccessModal} setModalShow={setShowSuccessModal} />
    </WebLayout>
  );
};

export default OticLanding;
