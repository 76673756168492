const currentDate = new Date();
export const basicOticLanding = {
  courseName: '',
  courseCode: '',
  startDate: currentDate,
  endDate: '',
  students: [],
  studentsString: ''
};
export const oticCompanyPlacerHolder = {
  landingPrimaryColor: '#64BFB0',
  landingSecondaryColor: '#D1ECE7',
  landingContentOne: 'Corporación de Capacitación de la Construcción',
  landingContentTwo: 'Formulario de generación de certificados de cobertura de\n Accidentes Personales',
  landingContentOneTypewriter: '',
  landingBackgroundInfo: { fileUrl: '/assets/banner-otic.webp' },
  multiLogo: true,
  textOnScroll: 'dark',
  newTab: true,
  logoInfo: [
    { key: 'otic-logo', fileUrl: '/assets/logo-otic.png', className: 'white-logo', preIconVariant: 'aqua' },
    {
      key: 'chubb-logo',
      fileUrl: '/assets/chubb.png',
      className: 'white-logo',
      preIconVariant: 'aqua'
    }
  ]
};
