import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal } from '../..';
import { sendAlert } from '../../../actions/utils';
import { ModalBodyIcon } from '../../Shared';
import BulkUploadForm from './BulkUploadForm';
import { adminBuklUploadContractItemRequest } from '../../../requests/admin/adminContractItems';
import { buklUploadContractItemRequest } from '../../../requests/contractItems';

const BulkUploadContractItem = ({ handleModalClose, setMoreData, userModule }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);

  const handleSuccessRequest = response => {
    setErrorMessage(response.data.join(', '));
    if (response.data.length > 0) return;

    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Bienes actualizados con éxito' }));
    setMoreData(m => !m);
  };

  const getBulkUploadRequest = () => {
    const requestMap = {
      admin: adminBuklUploadContractItemRequest,
      broker: buklUploadContractItemRequest
    };

    return requestMap[userModule];
  };

  const handleAdditionsRequest = (values, setSubmitting) => {
    setModalConfirmationShow(false);
    const bulkUploadRequest = getBulkUploadRequest();
    bulkUploadRequest({
      dispatch,
      params: snakeCaseKeys(values.contractItem),
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (values, setSubmitting) => {
    setModalConfirmationShow(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="Estás cargando el listado de"
        highlightedText="bienes"
        highlightedVariant="light-danger"
        formRequest={() => handleAdditionsRequest(values, setSubmitting)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  return (
    <>
      <BulkUploadForm
        textDescription={<TextDescription />}
        placeholder="RUT Cuenta; N° Contrato; N° Bien; Estado; Notas Bien; N° Póliza asociada; Ejecutivo contrato"
        contractItem={{ contractItems: '' }}
        formRequest={handleModalConfirmation}
        handleModalClose={handleModalClose}
        errorMessage={errorMessage}
      />

      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />
    </>
  );
};

const TextDescription = () => {
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  return (
    <>
      <p>Para cargar bienes de forma masiva:</p>
      <ul>
        <li className="ml-4">
          Ingresa un bien por línea con los siguientes datos: RUT Cuenta; N° Contrato; N° Bien; Estado; Notas Bien; N°
          Póliza asociada; Ejecutivo contrato
        </li>
        <li className="ml-4">
          Copia los datos desde una planilla <span className={commonClasses}>(excel, sheets, etc.)</span> o separa cada
          dato utilizando <span className={commonClasses}>;</span>
        </li>
        <li className="ml-4">
          Si estás ingresando más de un bien para el mismo contrato, en los campos Cuenta, Contrato, Estado y Ejecutivo
          de Contrato, debes ingresar los mismos datos. Si no están exactamente iguales verás un mensaje de error y no
          podrás hacer la carga.
        </li>
        <li className="ml-4">
          Si estás agregando bienes a un contrato que ya existe, los campos Cuenta, Contrato, Estado y Ejecutivo de
          Contrato se actualizarán con los datos que estás cargando ahora.
        </li>
      </ul>
    </>
  );
};

export default BulkUploadContractItem;
