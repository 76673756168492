import React from 'react';
import AccountSection from './AccountSection';
import ContractInfoSection from './ContractInfoSection';

const ContractFormFirstTab = ({ fromAdmin, modelName, userModule }) => {
  return (
    <>
      <AccountSection fromAdmin={fromAdmin} modelName={modelName} userModule={userModule} />
      <ContractInfoSection modelName={modelName} />
    </>
  );
};

export default ContractFormFirstTab;
