export const leadProps = ({ lead }) => {
  const { insurancePolicyProposal, isRenewal, renewableInsurancePolicy } = lead;
  const {
    contractProposalInfo,
    insuranceCategoryName,
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuranceCompanyName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter,
    insuranceCategoryCategory
  } = insurancePolicyProposal;

  const categoryEmail = insuranceCategoryCategory === 'life' ? ' claudialopez@nicoseguros.com' : 'ana@nicoseguros.com';
  const proposalTypeName = `Propuesta de ${isRenewal ? 'renovación' : 'contratación'}`;
  const toEmails = [insuranceCompanyExecutiveEmail].filter(email => email);
  const ccEmails = [categoryEmail].filter(email => email);

  return {
    modalTitle: { textConnector: 'una', text: proposalTypeName, recipient: insuranceCompanyFullName },
    to: toEmails,
    cc: ccEmails,
    subject: `NICO Seguros - ${proposalTypeName} n° ${policyNumber} para ${insuranceCompanyName}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la ${proposalTypeName.toLowerCase()} n° ${policyNumber}, de NICO Corredores de Seguros SpA a ${insuranceCompanyFullName}, según el siguiente detalle:<br><br>
    ${isRenewal ? `N° de póliza a renovar: ${renewableInsurancePolicy.policyNumber}<br>` : ''}
    Nombre asegurado: ${insuredPersonName}<br>
    RUT asegurado: ${insuredPersonNationalIdentification}<br>
    Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>
    Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>
    ¡Gracias!`,
    attachments: [{ ...contractProposalInfo, cleanFilename: `NICO Seguros - ${proposalTypeName} n° ${policyNumber}` }]
  };
};

export const leadQuotationProps = ({ lead, selectedQuotations, currentUser }) => {
  const { account, leadQuotations, subjectMatter, responsibleEmail } = lead;
  const filteredLeadQuotations = leadQuotations.filter(leadQuotation =>
    selectedQuotations.find(q => q.id === leadQuotation.id)
  );
  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);

  const leadQuotationAttachments = filteredLeadQuotations.map(leadQuotation => ({
    ...leadQuotation.quotation,
    cleanFilename: `NICO Seguros - Cotización ${leadQuotation.name}`
  }));

  const toEmails = [...commercialContactEmails, account.insuranceBrokerEmail, responsibleEmail];
  const ccEmails = [currentUser.email].filter(email => email);
  const toUniqueEmails = [...new Set(toEmails.filter(email => email))];
  const subjectMatterInfo = subjectMatter
    ? subjectMatter.split('Información de formulario de cotización:')[0].trim()
    : '[DESCRIPCIÓN GENERAL]';

  return {
    modalTitle: { textConnector: '', text: 'cotizaciones', recipient: account.name },
    to: toUniqueEmails,
    cc: ccEmails,
    subject: `NICO Seguros - Cotizaciones para ${account.name} - ${subjectMatterInfo}`,
    body: `Hola!<br><br>
    Adjunto en este correo encuentras cotizaciones de seguro solicitadas por ${account.name} para proteger su ${subjectMatterInfo}.<br><br>
    Puedes revisar todos los detalles y aprobar la cotización que más te guste ingresando a este <a href="" target="_blank">link</a>.<br><br>
    <a href="" target="_blank">Ver detalle</a><br><br>
    Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>
    ¡Gracias!<br><br>
    Saludos,<br>
    ${currentUser.name}`,
    attachments: leadQuotationAttachments
  };
};

export const insurancePolicyProps = ({ insurancePolicy }) => {
  const {
    account = {},
    accountName,
    contractInfo = {},
    insuranceCategoryName,
    insuranceCompany = {},
    insuranceCompanyFullName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter,
    responsibleEmail,
    insuranceBrokerEmail,
    insuranceCategoryCategory
  } = insurancePolicy;

  const categoryEmail = insuranceCategoryCategory === 'life' ? ' claudialopez@nicoseguros.com' : 'ana@nicoseguros.com';
  const toEmails = [responsibleEmail].filter(email => email);
  const ccEmails = [...new Set([insuranceBrokerEmail, categoryEmail].filter(email => email))];

  return {
    modalTitle: { textConnector: 'una', text: 'póliza', recipient: account.name || accountName },
    to: toEmails,
    cc: ccEmails,
    subject: `NICO Seguros - Póliza n° ${policyNumber} para ${account.name || accountName}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la póliza n° ${policyNumber}, donde encontrarás todos los detalles de la siguiente cobertura:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Compañía de seguros: ${insuranceCompany.fullName ||
      insuranceCompanyFullName}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [{ ...contractInfo, cleanFilename: `NICO Seguros - Póliza n° ${policyNumber}` }]
  };
};

export const insuranceEndorsementProps = ({ insurancePolicy }) => {
  const {
    account,
    insuranceCategoryName,
    insuranceEndorsementsAttributes: insuranceEndorsements,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);
  const endorsements = insuranceEndorsements.filter(obj => Object.keys(obj.companyEndorsementInfo).length > 0);

  const insuranceEndorsementAttachments = endorsements.map(insuranceEndorsement => ({
    ...insuranceEndorsement.companyEndorsementInfo,
    cleanFilename: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
  }));

  return {
    modalTitle: { textConnector: 'un', text: 'endoso', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Endosos a póliza n° ${policyNumber} para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras los endosos de la póliza n° ${policyNumber}, donde encontrarás las modificaciones realizadas, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: insuranceEndorsementAttachments
  };
};

export const insuranceProposalEndorsementProps = ({ insuranceEndorsement, insurancePolicy }) => {
  const {
    insuranceCategoryName,
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuranceCompanyName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  return {
    modalTitle: { textConnector: 'una', text: 'propuesta de endoso', recipient: insuranceCompanyFullName },
    to: [insuranceCompanyExecutiveEmail],
    subject: `NICO Seguros - Propuesta de endoso a póliza n° ${policyNumber} para ${insuranceCompanyName}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras la propuesta de endoso a la póliza n° ${policyNumber}, de NICO Corredores de Seguros SpA a ${insuranceCompanyFullName}, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...insuranceEndorsement.endorsementSummaryInfo,
        cleanFilename: `NICO Seguros - Propuesta de endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
      }
    ]
  };
};

export const insuranceCompanyEndorsementProps = ({ insuranceEndorsement, insurancePolicy }) => {
  const {
    account,
    insuranceCategoryName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber,
    subjectMatter
  } = insurancePolicy;

  const commercialContactEmails = account.commercialContacts.map(contact => contact.email);

  return {
    modalTitle: { textConnector: 'un', text: 'endoso', recipient: account.name },
    to: [...commercialContactEmails, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber} para ${account.name}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras el endoso n° ${insuranceEndorsement.id} a la póliza n° ${policyNumber}, donde encontrarás las modificaciones realizadas, según el siguiente detalle:<br><br>N° de póliza: ${policyNumber}<br>N° de endoso: ${insuranceEndorsement.id}<br>Tipo de endoso: ${insuranceEndorsement.translatedEndorsementType}<br>Nombre asegurado: ${insuredPersonName}<br>RUT asegurado: ${insuredPersonNationalIdentification}<br>Ramo: ${insuranceCategoryName}<br>Materia: ${subjectMatter}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...insuranceEndorsement.companyEndorsementInfo,
        cleanFilename: `NICO Seguros - Endoso n° ${insuranceEndorsement.id} a póliza n° ${policyNumber}`
      }
    ]
  };
};

export const beneficiaryMovementsProps = ({ beneficiaryMovement, insurancePolicy, currentUser }) => {
  const {
    account = {},
    insuranceCompanyExecutiveEmail,
    insuranceCompanyFullName,
    insuredPersonName,
    insuredPersonNationalIdentification,
    policyNumber
  } = insurancePolicy;

  const { additionsQuantity, exclusionsQuantity } = beneficiaryMovement;

  return {
    modalTitle: { textConnector: 'nuevos', text: 'movimientos', recipient: insuranceCompanyFullName },
    to: [currentUser.email, insuranceCompanyExecutiveEmail, account.insuranceBrokerEmail],
    subject: `NICO Seguros - Movimientos en póliza n° ${policyNumber}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras los nuevos movimientos de nómina de la póliza n° ${policyNumber}, donde encontrarás todos los detalles de los siguientes movimientos:<br><br>N° de póliza: ${policyNumber}<br>Nombre contratante: ${insuredPersonName}<br>RUT contratante: ${insuredPersonNationalIdentification}<br>Número de incorporaciones: ${additionsQuantity}<br>Número de exclusiones: ${exclusionsQuantity}<br><br>Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>¡Gracias!`,
    attachments: [
      {
        ...beneficiaryMovement?.movementSummaryInfo,
        cleanFilename: `NICO Seguros - Movimientos en póliza n° ${policyNumber}`
      }
    ]
  };
};

export const premiumCollectionDebtProps = ({ premiumCollection }) => {
  const {
    accountName,
    additionalDocumentsInfo,
    collectionEmail,
    debtAmount,
    debtInstallment,
    debtStartDate,
    executiveManagerEmail,
    hiringPersonName,
    hiringPersonNatIdentification,
    insuranceBrokerName,
    insurancePolicyPolicyNumber,
    categoryName,
    companyName,
    paymentLink,
    paymentTransferData,
    subjectMatter
  } = premiumCollection;

  const ccEmails = ['cobranza@nicoseguros.com', executiveManagerEmail].filter(email => email);
  const toEmails = [collectionEmail].filter(email => email);
  const paymentTransferDatainfo = paymentTransferData
    ? `Si prefieres hacer una transferencia, usa los datos detallados a continuación 
  y no olvides enviarnos el comprobante de transferencia a cobranza@nicoseguros.com<br><br>
  ${paymentTransferData}<br><br>`
    : '';

  const paymentLinkInfo = paymentLink
    ? `Para pagar la prima de tu seguro, prefiere siempre el pago en línea a la compañía de seguros. 
  Es más fácil y rápido. Para hacerlo, haz click en el siguiente link.<br><br>
  <a href="${paymentLink}" target="_blank">Pago en línea</a><br><br>`
    : '';

  const debtInstallmentInfo = debtInstallment ? `<b>Nº de cuota adeudada:</b> ${debtInstallment}<br><br>` : '';

  return {
    modalTitle: { textConnector: 'un', text: 'correo de cobranza', recipient: accountName },
    from: 'cobranza@nicoseguros.com',
    to: toEmails,
    cc: ccEmails,
    subject: `Recordatorio de pago de tu póliza de seguro`,
    body: `Hola!<br><br>
    Tu protección es nuestra prioridad. Para esto, mantener tu póliza al día es clave. Este es un recordatorio amistoso sobre el pago de tu póliza.<br><br>
    <b>¿Ya hiciste el pago? ¡Perfecto! Ignora este mensaje.</b><br>
    La información entregada por las Compañías de Seguros y los Bancos podría estar desactualizada respecto a los últimos pagos, incluso si tienes PAC o PAT. Lamentamos cualquier problema que esto te pueda traer.<br><br>
    <b>Nombre contratante:</b> ${hiringPersonName}<br>
    <b>RUT contratante:</b> ${hiringPersonNatIdentification}<br>
    <b>Nombre corredor:</b> ${insuranceBrokerName} <br>
    <b>Nº de póliza:</b> ${insurancePolicyPolicyNumber}<br>
    <b>Compañía de seguros:</b> ${companyName}<br>
    <b>Ramo:</b> ${categoryName}<br>
    <b>Materia asegurada:</b> ${subjectMatter}<br>
    <b>Fecha de vencimiento de deuda:</b> ${debtStartDate}<br>
    <b>Monto pendiente de pago:</b> ${debtAmount}<br>
    ${debtInstallmentInfo}
    ${paymentLinkInfo}
    ${paymentTransferDatainfo}
    La continuidad de tu cobertura depende del pago puntual de tu póliza. Recuerda que siempre puedes revisar todas tus pólizas, cotizar más seguros o pedir asesoría en tu portal del asegurado ingresando a www.nicoseguros.com<br><br>
    Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>
    ¡Gracias!`,
    attachments: additionalDocumentsInfo || []
  };
};

export const premiumCollectionMonthlyProps = ({ premiumCollection }) => {
  const {
    accountName,
    accountNationalId,
    additionalDocumentsInfo,
    collectionEmail,
    companyName,
    coveragePeriod,
    currentDate,
    insurancePolicyPolicyNumber,
    invoiceNumber,
    parsedTotalPremiumReported,
    paymentTransferData
  } = premiumCollection;

  const toEmails = [collectionEmail].filter(email => email);
  const ccEmails = ['cobranza@nicoseguros.com'];
  const paymentTransferDatainfo = paymentTransferData
    ? `${paymentTransferData}<br><br>`
    : '[DATOS DE TRANSFERENCIA DE ASEGURADORA]<br><br>';

  return {
    modalTitle: { textConnector: 'un', text: 'correo de cobranza', recipient: accountName },
    from: 'cobranza@nicoseguros.com',
    to: toEmails,
    cc: ccEmails,
    subject: `Envío nómina y facturas período ${coveragePeriod} - ${accountName}`,
    body: `Envío nómina y facturas por cobertura de ${coveragePeriod}<br><br>
    Hola!<br><br>
    Tu protección es nuestra prioridad. Para esto, mantener tu póliza al día es clave.<br>
    Adjunto nóminas de cobranzas y facturas de tu seguro colectivo.<br><br>
    <b>Cuenta:</b> ${accountName}<br>
    <b>RUT:</b> ${accountNationalId}<br>
    <b>Nº de póliza:</b> ${insurancePolicyPolicyNumber}<br>
    <b>Compañía de seguros:</b> ${companyName}<br><br>
    <b>Factura(s):</b> ${invoiceNumber}<br>
    <b>Período de cobertura:</b> ${coveragePeriod}<br>
    <b>Monto a pagar:</b> ${parsedTotalPremiumReported}<br><br>
    Esta cobranza incluye las altas y bajas procesadas hasta el: ${currentDate}<br><br>
    Para pagar la prima de tu seguro, usa los datos detallados a continuación y no olvides enviarnos el comprobante de transferencia a cobranza@nicoseguros.com<br><br> 
    ${paymentTransferDatainfo}
    La continuidad de tu cobertura depende del pago puntual de tu póliza.<br>
    Recuerda que siempre puedes revisar todas tus pólizas, cotizar más seguros o pedir asesoría en tu portal del asegurado ingresando a www.nicoseguros.com.<br><br>
    Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>
    ¡Gracias!`,
    attachments: additionalDocumentsInfo || []
  };
};

export const insuranceContractProps = ({ contractItem, currentUser }) => {
  const { insuranceContractInfo = {} } = contractItem;

  const { contractNumber, contractInfo } = insuranceContractInfo;

  const toEmails = [];
  const ccEmails = [currentUser.email].filter(email => email);

  return {
    modalTitle: { textConnector: 'un', text: 'contrato' },
    to: toEmails,
    cc: ccEmails,
    subject: `NICO Seguros - Contrato n° ${insuranceContractInfo?.contractNumber}`,
    body: `Hola!<br><br>Adjunto en este correo encuentras el contrato n° ${contractNumber}, donde encontrarás todos los detalles:<br><br>
    N° de contrato: ${contractNumber}<br><br>
    Cualquier pregunta que tengas o información adicional que necesites, puedes responder a este mismo correo y te ayudaremos lo antes posible.<br><br>
    ¡Gracias!`,
    attachments: [{ ...contractInfo, cleanFilename: `NICO Seguros - Contrato n° ${contractNumber}` }]
  };
};
