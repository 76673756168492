import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ModalBodyIcon } from '../Shared';
import { InputRemoteSelect } from '../Utils/Select';
import { useFetchForRemoteSelect } from '../../hooks';
import {
  associateAdminContractItemsRequest,
  debounceIndexAdminInsurancePoliciesRequest,
  indexAdminInsurancePolicyRequest
} from '../../requests/admin/adminInsurancePolicies';
import { sendAlert } from '../../actions/utils';
import {
  associateContractItemsRequest,
  debounceIndexInsurancePoliciesRequest,
  indexInsurancePolicyRequest
} from '../../requests/insurancePolicies';

const AssociatePolicyModal = ({ handleModalClose, selectedItems, setMoreData, userModule }) => {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const getIndexRequest = () => {
    const requestMap = {
      admin: indexAdminInsurancePolicyRequest,
      broker: indexInsurancePolicyRequest
    };
    return requestMap[userModule];
  };

  const getDebouncedIndexRequest = () => {
    const requestMap = {
      admin: debounceIndexAdminInsurancePoliciesRequest,
      broker: debounceIndexInsurancePoliciesRequest
    };
    return requestMap[userModule];
  };

  const getAssociateRequest = () => {
    const requestMap = {
      admin: associateAdminContractItemsRequest,
      broker: associateContractItemsRequest
    };
    return requestMap[userModule];
  };

  const policySelectorParams = () => {
    const paramsMap = {
      admin: { display_length: 500 },
      broker: { from_company: true }
    };
    return paramsMap[userModule];
  };

  const { options, selectedOption, fetchOptions: fetchInsurancePolicies } = useFetchForRemoteSelect({
    indexRequest: getIndexRequest(),
    debouncedIndexRequest: getDebouncedIndexRequest(),
    value: selectedPolicy?.value,
    initialParams: {
      for_selector: true,
      is_valid: true,
      sort_column: 'created_at',
      sort_direction: 'asc',
      ...policySelectorParams()
    }
  });

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Bienes actualizados con éxito' }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleAssociateRequest = () => {
    setSubmitting(true);
    const associateRequest = getAssociateRequest();
    associateRequest(selectedPolicy.value, {
      dispatch,
      params: { contract_item_ids: [...selectedItems] },
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <ModalBodyIcon
      content="Asociar bien/es a póliza"
      confirmText={false}
      highlightedText=""
      highlightedVariant="white"
      showIcon={false}
      subContent={
        <>
          <p>Has seleccionado {selectedItems.length} bienes para asociar a una póliza.</p>
          <p>A continuación, puedes buscar y seleccionar la póliza que corresponda.</p>

          <Row>
            <Col md={12}>
              <InputRemoteSelect
                isClearable
                label="Número de póliza"
                placeholder="Seleccionar póliza"
                defaultOptions={options}
                value={selectedOption}
                request={fetchInsurancePolicies}
                onChange={data => {
                  setSelectedPolicy(data);
                }}
              />
            </Col>
            <Col md={12}>
              {selectedPolicy && (
                <>
                  <p>Aseguradora: {selectedPolicy.insurance_company_name} </p>
                  <p>Ramo: {selectedPolicy.insurance_category_name}</p>
                  <p>Inicio de vigencia póliza: {selectedPolicy.validity_start}</p>
                  <p>Término de vigencia póliza: {selectedPolicy.validity_end}</p>
                </>
              )}
            </Col>
          </Row>
        </>
      }
      formRequest={handleAssociateRequest}
      handleModalClose={handleModalClose}
      submitBtnText="Asociar"
      isSubmitting={!selectedPolicy || submitting}
    />
  );
};

export default AssociatePolicyModal;
