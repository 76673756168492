import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { basicInsuranceCover } from '../../../components/InsurancePolicies/Forms/basicInsuranceCover';
import basicInsuranceItem from '../../../components/InsurancePolicies/Forms/basicInsuranceItem';
import {
  createAdminInsurancePolicyRequest,
  nextProposalIndentification
} from '../../../requests/admin/adminInsurancePolicies';
import {
  addOneYear,
  excludeKeys,
  extractKeys,
  redirectTo,
  recursiveArrayObjectEmptyKeys
} from '../../../services/utils';
import InsurancePolicyForm from '../../InsurancePolicy/InsurancePolicyForm';
import adminBasicInsurancePolicy from './adminBasicInsurancePolicy';
import useConvertDictionaryKeys from '../../../hooks/useConvertDictionaryKeys';

const AdminInsurancePolicyNew = () => {
  const modelUrl = '/admin/insurance_policies';

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { isRenewal, lead, leadModelUrl, leadPath, policyType, validityStart } = !!location.state && location.state;
  const isProposal = policyType === 'proposal';

  const [accountReference, setAccountReference] = useState('');
  const [insuranceBrokerReference, setInsuranceBrokerReference] = useState('');
  const [insurancePolicy, setInsurancePolicy] = useState(adminBasicInsurancePolicy);
  const [modalShow, setModalShow] = useState(false);
  const { convertDictionaryKeys } = useConvertDictionaryKeys();

  let handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Crear nueva póliza' }
  ];

  const fromLeadBreadcrumb = [
    { key: 1, name: isRenewal ? 'Renovaciones' : 'Ventas', href: leadModelUrl },
    { key: 2, name: `Detalle de ${isRenewal ? 'renovación' : 'oportunidad'}`, href: leadPath },
    { key: 3, name: 'Crear nueva propuesta' }
  ];

  if (isProposal) handleBreadcrumb = fromLeadBreadcrumb;

  const handleRedirectToBeneficiaries = response => {
    if (response.data.insurance_company.category === 'life') {
      history.push({
        pathname: `${modelUrl}/${response.data.id}/beneficiaries`,
        state: { originPath: `${modelUrl}/${response.data.id}`, insurancePolicy: response.data }
      });
    } else {
      redirectTo(history, `${modelUrl}/${response.data.id}`);
    }
  };

  const handleSuccessCreate = response => {
    dispatch(sendAlert({ kind: 'success', message: `${isProposal ? 'Propuesta' : 'Póliza'} creada con éxito` }));

    if (isProposal && lead) redirectTo(history, leadPath);
    else if (isProposal && !lead) redirectTo(history, `${leadPath}/${response.data.lead.value}`);
    else handleRedirectToBeneficiaries(response);
  };

  const handleSuccessfetchProposalIdentification = response => {
    setInsurancePolicy({ ...insurancePolicy, policyNumber: response.data });
  };

  const fetchProposalIdentification = () => {
    nextProposalIndentification({
      dispatch,
      successCallback: handleSuccessfetchProposalIdentification
    });
  };

  const completeCommentsFromLead = keys => {
    const updatedKeys = keys;
    updatedKeys.comments = lead?.comments || updatedKeys.comments;
    updatedKeys.adminComments = lead?.adminComments || keys.adminComments;
    return updatedKeys;
  };

  const shouldShowDetail = (detail, item) => {
    const { showif } = detail;
    if (!showif) return true;

    const { id, selected_option: selectedOption } = showif;
    const selectedValue = item[id];
    return selectedValue && selectedOption.includes(selectedValue);
  };

  const handleParams = ({ acceptance, contract, contractFile, contractProposal, quotation, ...currentObject }) => {
    const contractKeys = ['contract', 'contractProposal'];
    const proposalKeys = ['acceptance', 'proposalBody', 'proposalTitle', 'quotation', 'contractFile'];
    let sendParams = snakeCaseKeys(excludeKeys(currentObject, isProposal ? contractKeys : proposalKeys));

    if (sendParams.insurance_items_attributes) {
      const otherFields = sendParams.dictionary_details?.filter(detail => detail.id.startsWith('other'));

      sendParams.insurance_items_attributes.forEach((item, index) => {
        const otherFieldsString = otherFields
          ?.filter(detail => item[detail.id] !== undefined && shouldShowDetail(detail, item))
          .map(detail => `${detail.title}: ${item[detail.id]}`)
          .join('\n');

        if (item.notes) {
          sendParams.insurance_items_attributes[index].notes += `\n\n${otherFieldsString}`;
        } else {
          sendParams.insurance_items_attributes[index].notes = otherFieldsString;
        }
      });

      sendParams.insurance_items_attributes = convertDictionaryKeys(sendParams.insurance_items_attributes);
    }

    if (isProposal) sendParams = { ...sendParams, acceptance, quotation, contract_file: contractFile };
    else sendParams = { ...sendParams, contract, contract_proposal: contractProposal };

    if (lead) sendParams.lead_id = lead.id;

    return sendParams;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insurancePolicy);

    createAdminInsurancePolicyRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => {
        setSubmitting(false);
        setModalShow(false);
      }
    });
  };

  const handleAttributesIds = ({ currentData }) =>
    currentData.insuranceItemsAttributes.map(item => {
      const insuranceItem = excludeKeys(item, ['id']);
      insuranceItem.insuranceCoversAttributes = item.insuranceCoversAttributes.map(cover => excludeKeys(cover, ['id']));
      return insuranceItem;
    });

  const handleCompleteFieldsFromLead = ({ currentInsurancePolicy }) => {
    let currentData = currentInsurancePolicy;
    const deleteKeys = ['id', 'policyNumber', 'policyType'];
    const insurancePolicyKeys = Object.keys(adminBasicInsurancePolicy);

    if (lead.insurancePolicyProposal) {
      const proposalData = extractKeys(lead.insurancePolicyProposal, insurancePolicyKeys, deleteKeys, true);
      currentData = { ...currentData, ...proposalData };
      currentData.insuranceItemsAttributes = handleAttributesIds({ currentData });
      currentData.additionalDocumentsInfo = [...lead.additionalDocumentsInfo, proposalData.quotationInfo];
      return currentData;
    }

    const leadData = extractKeys(lead, insurancePolicyKeys, deleteKeys, true);
    currentData = { ...currentData, ...leadData, fromPolicyNumber: lead.renewableInsurancePolicy.policyNumber };

    if (policyType === 'proposal') currentData.policyNumber = lead.nextProposalCorrelativeIdentification;

    if (isRenewal) {
      const leadRenewable = lead.renewableInsurancePolicy;
      currentData.validityStart = validityStart;
      currentData.validityEnd = addOneYear(moment(validityStart, 'DD-MM-YYYY'));
      currentData.grossInstallment = leadRenewable?.grossInstallment;
      currentData.parsedGrossInstallment = leadRenewable?.parsedGrossInstallment;
      currentData.affectCommissionPercentage = leadRenewable?.affectCommissionPercentage;
      currentData.parsedAffectCommissionPercentage = leadRenewable?.parsedAffectCommissionPercentage;
      currentData.exemptCommissionPercentage = leadRenewable?.exemptCommissionPercentage;
      currentData.parsedExemptCommissionPercentage = leadRenewable?.parsedExemptCommissionPercentage;
      currentData.renewalType = leadRenewable?.renewalType;
      currentData.paymentPlanId = leadRenewable?.paymentPlanId;
      currentData.paymentPlanName = leadRenewable?.paymentPlanName;
      currentData.paymentMethod = leadRenewable?.paymentMethod;
      currentData.insuranceCompanyId = leadRenewable?.selectedLeadQuotation?.insuranceCompanyId || '';
      currentData.additionalDocumentsInfo = leadRenewable?.additionalDocumentsInfo || [];
    }

    currentData = {
      ...currentData,
      insuranceCompanyId: lead.selectedLeadQuotation?.insuranceCompanyId || currentData.insuranceCompanyId || '',
      insuranceCompanyName: lead.selectedLeadQuotation?.insuranceCompanyName || '',
      insuranceCompanyFullName: lead.selectedLeadQuotation?.insuranceCompanyFullName || '',
      insuranceItemsAttributes:
        lead.insuranceItemsAttributes.length > 0
          ? recursiveArrayObjectEmptyKeys(lead.insuranceItemsAttributes, ['id'])
          : [
              {
                ...basicInsuranceItem,
                insuranceCoversAttributes: [
                  {
                    ...basicInsuranceCover,
                    amount: lead.coverAmount,
                    name: lead.coverDetail,
                    netPremium: lead.estimatedNetPrime,
                    parsedAmount: lead.parsedCoverAmount
                  }
                ]
              }
            ]
    };

    if (currentData.insuranceItemsAttributes && currentData.insuranceItemsAttributes.length > 0) {
      currentData.insuranceItemsAttributes = currentData.insuranceItemsAttributes.map(item => {
        if (!item.insuranceCoversAttributes || item.insuranceCoversAttributes.length === 0) {
          return {
            ...item,
            insuranceCoversAttributes: [basicInsuranceCover]
          };
        }
        return item;
      });
    }

    return currentData;
  };

  const fetchInsurancePolicy = () => {
    let updatedKeys = { ...insurancePolicy, insuranceBrokerId: insuranceBrokerReference, accountId: accountReference };
    if (lead) updatedKeys = handleCompleteFieldsFromLead({ currentInsurancePolicy: updatedKeys });
    if (!lead && isProposal) fetchProposalIdentification();
    if (!isProposal) updatedKeys = completeCommentsFromLead(updatedKeys);
    setInsurancePolicy({ ...updatedKeys, policyType });
  };

  const handleSetAccountOnMount = () => {
    const { state } = location;
    if (!state) return;
    const { accountId, insuranceBrokerId } = state;
    setAccountReference(accountId);
    setInsuranceBrokerReference(insuranceBrokerId);
  };

  useEffect(handleSetAccountOnMount, [location]);
  useEffect(fetchInsurancePolicy, [location.state, insuranceBrokerReference, accountReference]);

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={
          <InsurancePolicyForm
            userModule="admin"
            fromAdmin
            action="new"
            modelUrl={modelUrl}
            insurancePolicy={insurancePolicy}
            formRequest={handleCreateRequest}
            proposalOptions={{ isProposal, isRenewal, modalShow, setModalShow }}
            fromLeadProposal={!!(lead && lead.insurancePolicyProposal)}
            leadPresent={!!lead}
            setInsurancePolicy={setInsurancePolicy}
          />
        }
      />
    </div>
  );
};

export default AdminInsurancePolicyNew;
