import moment from 'moment';

const currentDate = moment().format('DD/MM/YYYY');

const basicContractItem = {
  id: '',
  comments: '',
  signatureDate: currentDate,
  insuranceContractInfo: {},
  insuranceContractId: ''
};

export default basicContractItem;
