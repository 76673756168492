import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect } from '../../hooks';
import {
  debounceIndexAdminInsurancePoliciesRequest,
  indexAdminInsurancePolicyRequest
} from '../../requests/admin/adminInsurancePolicies';
import { InputRemoteSelect } from '../Utils/Select';
import { debounceIndexInsurancePoliciesRequest, indexInsurancePolicyRequest } from '../../requests/insurancePolicies';

const InsurancePolicyRemoteSelector = ({
  field,
  modelName,
  initialParams,
  nestedDependency = null,
  tooltipText,
  userModule,
  label,
  placeholder,
  setExtraSelectedOption = () => null
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const getIndexRequest = () => {
    const requestMap = {
      admin: indexAdminInsurancePolicyRequest,
      broker: indexInsurancePolicyRequest
    };
    return requestMap[userModule];
  };

  const getDebouncedIndexRequest = () => {
    const requestMap = {
      admin: debounceIndexAdminInsurancePoliciesRequest,
      broker: debounceIndexInsurancePoliciesRequest
    };
    return requestMap[userModule];
  };

  const {
    options: insurancePolicies,
    selectedOption: selectedInsurancePolicy,
    fetchOptions: fetchInsurancePolicies
  } = useFetchForRemoteSelect({
    indexRequest: getIndexRequest(),
    debouncedIndexRequest: getDebouncedIndexRequest(),
    value: getIn(values, `${modelName}[insurancePolicyId]`),
    initialParams: {
      for_selector: true,
      is_valid: true,
      sort_column: 'created_at',
      sort_direction: 'asc',
      ...initialParams
    },
    nestedDependency
  });

  return (
    <InputRemoteSelect
      {...field}
      isClearable
      label={label || 'Número de póliza'}
      placeholder={placeholder || 'Seleccionar póliza'}
      defaultOptions={insurancePolicies}
      value={selectedInsurancePolicy}
      request={fetchInsurancePolicies}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
        setFieldValue(`${modelName}[insurancePolicyNumber]`, data?.label || '');
        setExtraSelectedOption(data);
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
      tooltipText={tooltipText}
    />
  );
};

export default InsurancePolicyRemoteSelector;
