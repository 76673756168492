import React from 'react';

import { excludeKeys } from '../../../services/utils';
import { InfoCard } from '../../Utils/Cards';
import generalItemObject from './generalItemObject';

const InfoCardItem = ({ contractItem, userModule, hideAttributes }) => {
  let generalItemInfo = generalItemObject({ contractItem, userModule });
  generalItemInfo = excludeKeys(generalItemInfo, hideAttributes);

  return <InfoCard title="Información del bien" object={generalItemInfo} />;
};

export default InfoCardItem;
