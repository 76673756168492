import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  AdminLoggedLayout,
  InsuredLoggedLayout,
  LandingLayout,
  LoggedLayout,
  NonLoggedLayout,
  PublicLayout
} from './layouts';

import {
  AccountEdit,
  AccountIndex,
  AccountNew,
  AccountShow,
  AdminAccountEdit,
  AdminAccountIndex,
  AdminAccountNew,
  AdminAccountShow,
  AdminBeneficiaryIndex,
  AdminBeneficiaryMovementsIndex,
  AdminBrokerLandingSettings,
  AdminBlogIndex,
  AdminBlogNew,
  AdminBlogShow,
  AdminBlogEdit,
  AdminClientLandingSettings,
  AdminCommissionCollectionIndex,
  AdminCommuneEdit,
  AdminCommuneIndex,
  AdminCommuneNew,
  AdminCompanyEdit,
  AdminCompanyIndex,
  AdminCompanyNew,
  AdminCompanyShow,
  AdminContractEdit,
  AdminContractIndex,
  AdminContractNew,
  AdminContractShow,
  AdminHomeIndex,
  AdminIndustryEdit,
  AdminIndustryIndex,
  AdminIndustryNew,
  AdminInsuranceCategoryEdit,
  AdminInsuranceCategoryIndex,
  AdminInsuranceCategoryNew,
  AdminInsuranceCompanyEdit,
  AdminInsuranceCompanyIndex,
  AdminInsuranceCompanyNew,
  AdminInsuranceEndorsementNew,
  AdminInsuranceEndorsementEdit,
  AdminInsuranceEndorsementCompanyEdit,
  AdminInsurancePolicyEdit,
  AdminInsurancePolicyIndex,
  AdminInsurancePolicyNew,
  AdminInsurancePolicyShow,
  AdminLeadEdit,
  AdminLeadIndex,
  AdminLeadNew,
  AdminLeadRenewalIndex,
  AdminLeadRenewalShow,
  AdminLeadShow,
  AdminPaymentPlanEdit,
  AdminPaymentPlanIndex,
  AdminPaymentPlanNew,
  AdminPremiumCollectionIndex,
  AdminProvinceEdit,
  AdminProvinceIndex,
  AdminProvinceNew,
  AdminRegionEdit,
  AdminRegionIndex,
  AdminRegionNew,
  AdminReportIndex,
  AdminSettingIndex,
  AdminSlideEdit,
  AdminSlideIndex,
  AdminSlideNew,
  AdminUserEdit,
  AdminUserIndex,
  AdminUserNew,
  AdminMyAgendaIndex,
  AdminAllTasksIndex,
  AllTasksIndex,
  BeneficiaryIndex,
  BeneficiaryMovementsIndex,
  BrokerLanding,
  BrokerBlog,
  BrokerBlogShow,
  BrokerPlans,
  BrokerContact,
  BrokerDemoContact,
  ClientLanding,
  CommissionCollectionIndex,
  CompanyLanding,
  ContactIndex,
  HomeContact,
  HomeTerms,
  HomeTermsServices,
  HomeIndex,
  InsuredBlog,
  InsuredBlogShow,
  InsuredCompanies,
  InsuredPersons,
  InsuranceCompanyLogoEdit,
  InsuranceCompanyLogoIndex,
  InsuranceCompanyLogoNew,
  InsuranceEndorsementNew,
  InsuranceEndorsementEdit,
  InsuranceEndorsementCompanyEdit,
  InsurancePolicyIndex,
  InsurancePolicyNew,
  InsurancePolicyShow,
  InsuredCommissionCollectionIndex,
  InsuredContactIndex,
  InsuredHomeIndex,
  InsuredInsuranceEndorsementCompanyEdit,
  InsuredInsuranceEndorsementEdit,
  InsuredInsuranceEndorsementNew,
  InsuredInsurancePolicyEdit,
  InsuredInsurancePolicyIndex,
  InsuredInsurancePolicyNew,
  InsuredInsurancePolicyShow,
  InsuredLeadIndex,
  InsuredLeadShow,
  InsuredLoginIndex,
  InsuredPremiumCollectionIndex,
  InsuredSinisterIndex,
  LeadEdit,
  LeadIndex,
  LeadNew,
  LeadQuotationApproval,
  LeadRenewalIndex,
  LeadRenewalShow,
  LeadRequestQuotation,
  LeadShow,
  LoginIndex,
  MultiLanding,
  MyAgendaIndex,
  NotFound,
  OticLanding,
  ProfileEdit,
  PartnerLanding,
  PartnerContact,
  PartnerDemoContact,
  InsuredAccountEdit,
  InsuredAccountIndex,
  InsuredAccountNew,
  InsuredAccountShow,
  InsuredContact,
  InsuredLeadNew,
  PartnerBlog,
  PartnerBlogShow,
  PartnerHowDoesItWork,
  PremiumCollectionIndex,
  RecoverPasswordIndex,
  ReportIndex,
  ResetPasswordIndex,
  BulkUploadIndex,
  UserEdit,
  UserIndex,
  UserNew,
  UserShow,
  InsurancePolicyEdit,
  AdminLeadRequestQuotation,
  ContractIndex,
  ContractNew,
  ContractShow,
  ContractEdit
} from '../screens';

const routes = (
  <Switch>
    {/* Platform routes */}

    <LoggedLayout exact path="/home" component={HomeIndex} />
    <LoggedLayout exact path="/profile" component={ProfileEdit} />

    <LoggedLayout exact path="/users" component={UserIndex} />
    <LoggedLayout exact path="/users/new" component={UserNew} />
    <LoggedLayout exact path="/users/:id" component={UserShow} />
    <LoggedLayout exact path="/users/:id/edit" component={UserEdit} />

    <LoggedLayout exact path="/accounts" component={AccountIndex} />
    <LoggedLayout exact path="/accounts/new" component={AccountNew} />
    <LoggedLayout exact path="/accounts/:id/" component={AccountShow} />
    <LoggedLayout exact path="/accounts/:id/edit" component={AccountEdit} />
    <LoggedLayout exact path="/accounts/:id/contacts" component={ContactIndex} />

    <LoggedLayout exact path="/contracts" component={ContractIndex} checkAccess="show_contracts_module" />
    <LoggedLayout exact path="/contracts/new" component={ContractNew} checkAccess="show_contracts_module" />
    <LoggedLayout exact path="/contracts/:id" component={ContractShow} checkAccess="show_contracts_module" />
    <LoggedLayout exact path="/contracts/:id/edit" component={ContractEdit} checkAccess="show_contracts_module" />

    <LoggedLayout exact path="/insurance_policies" component={InsurancePolicyIndex} />
    <LoggedLayout exact path="/insurance_policies/new" component={InsurancePolicyNew} />
    <LoggedLayout exact path="/insurance_policies/:id" component={InsurancePolicyShow} />
    <LoggedLayout exact path="/insurance_policies/:id/edit" component={InsurancePolicyEdit} />

    <LoggedLayout exact path="/insurance_policies/:id/endorsements/new" component={InsuranceEndorsementNew} />

    <LoggedLayout exact path="/insurance_policies/:id/endorsements/:id/edit" component={InsuranceEndorsementEdit} />

    <LoggedLayout
      exact
      path="/insurance_policies/:id/endorsements/:id/company_edit"
      component={InsuranceEndorsementCompanyEdit}
    />

    <LoggedLayout exact path="/insurance_policies/:id/beneficiaries" component={BeneficiaryIndex} />
    <LoggedLayout exact path="/insurance_policies/:id/movements" component={BeneficiaryMovementsIndex} />

    <LoggedLayout exact path="/leads" component={LeadIndex} />
    <LoggedLayout exact path="/leads/new" component={LeadNew} />
    <LoggedLayout exact path="/leads/:id" component={LeadShow} />
    <LoggedLayout exact path="/leads/:id/edit" component={LeadEdit} />
    <LoggedLayout exact path="/leads/:id/request_quotation" component={LeadRequestQuotation} />

    <LoggedLayout exact path="/agenda" component={MyAgendaIndex} />
    <LoggedLayout exact path="/agenda/all" component={AllTasksIndex} />

    <LoggedLayout exact path="/renewals" component={LeadRenewalIndex} />
    <LoggedLayout exact path="/renewals/:id" component={LeadRenewalShow} />
    <LoggedLayout exact path="/renewals/:id/request_quotation" component={LeadRequestQuotation} />
    <LoggedLayout exact path="/reports" component={ReportIndex} />

    <LoggedLayout exact path="/premium_collections" component={PremiumCollectionIndex} />
    <LoggedLayout exact path="/commission_collections" component={CommissionCollectionIndex} />

    {/* Insured Screens */}

    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/home" component={InsuredHomeIndex} />

    <InsuredLoggedLayout insuredGamma exact path="/insured/accounts" component={InsuredAccountIndex} />
    <InsuredLoggedLayout insuredGamma exact path="/insured/accounts/new" component={InsuredAccountNew} />
    <InsuredLoggedLayout insuredGamma exact path="/insured/accounts/:id" component={InsuredAccountShow} />
    <InsuredLoggedLayout insuredGamma exact path="/insured/accounts/:id/edit" component={InsuredAccountEdit} />
    <InsuredLoggedLayout insuredGamma exact path="/insured/accounts/:id/contacts" component={ContactIndex} />
    <InsuredLoggedLayout
      insuredDelta
      insuredGamma
      exact
      path="/insured/insurance_policies"
      component={InsuredInsurancePolicyIndex}
    />
    <InsuredLoggedLayout
      insuredGamma
      exact
      path="/insured/insurance_policies/new"
      component={InsuredInsurancePolicyNew}
    />
    <InsuredLoggedLayout
      insuredDelta
      insuredGamma
      exact
      path="/insured/insurance_policies/:id"
      component={InsuredInsurancePolicyShow}
    />
    <InsuredLoggedLayout
      insuredGamma
      exact
      path="/insured/insurance_policies/:id/edit"
      component={InsuredInsurancePolicyEdit}
    />

    <InsuredLoggedLayout
      exact
      insuredGamma
      path="/insured/insurance_policies/:id/endorsements/new"
      component={InsuredInsuranceEndorsementNew}
    />
    <InsuredLoggedLayout
      exact
      insuredGamma
      path="/insured/insurance_policies/:id/endorsements/:id/edit"
      component={InsuredInsuranceEndorsementEdit}
    />
    <InsuredLoggedLayout
      exact
      insuredGamma
      path="/insured/insurance_policies/:id/endorsements/:id/company_edit"
      component={InsuredInsuranceEndorsementCompanyEdit}
    />

    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/leads" component={InsuredLeadIndex} />
    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/leads/new" component={InsuredLeadNew} />
    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/leads/:id" component={InsuredLeadShow} />

    <InsuredLoggedLayout
      insuredDelta
      insuredGamma
      exact
      path="/insured/premium_collections"
      component={InsuredPremiumCollectionIndex}
    />
    <InsuredLoggedLayout
      insuredDelta
      insuredGamma
      exact
      path="/insured/commission_collections"
      component={InsuredCommissionCollectionIndex}
    />

    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/claims" component={InsuredSinisterIndex} />

    <InsuredLoggedLayout insuredDelta insuredGamma exact path="/insured/contact" component={InsuredContactIndex} />

    {/* Admin Screens */}

    <AdminLoggedLayout exact adminLight adminStandard path="/admin/home" component={AdminHomeIndex} />
    <AdminLoggedLayout exact adminStandard path="/admin/profile" component={ProfileEdit} />

    <AdminLoggedLayout exact adminStandard path="/admin/accounts" component={AdminAccountIndex} />
    <AdminLoggedLayout exact adminStandard path="/admin/accounts/new" component={AdminAccountNew} />
    <AdminLoggedLayout exact adminStandard path="/admin/accounts/:id" component={AdminAccountShow} />
    <AdminLoggedLayout exact adminStandard path="/admin/accounts/:id/edit" component={AdminAccountEdit} />
    <AdminLoggedLayout exact adminStandard path="/admin/accounts/:id/contacts" component={ContactIndex} />

    <AdminLoggedLayout exact adminStandard path="/admin/insurance_policies" component={AdminInsurancePolicyIndex} />
    <AdminLoggedLayout exact adminStandard path="/admin/insurance_policies/new" component={AdminInsurancePolicyNew} />
    <AdminLoggedLayout exact adminStandard path="/admin/insurance_policies/:id" component={AdminInsurancePolicyShow} />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/edit"
      component={AdminInsurancePolicyEdit}
    />

    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/beneficiaries"
      component={AdminBeneficiaryIndex}
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/movements"
      component={AdminBeneficiaryMovementsIndex}
    />

    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/endorsements/new"
      component={AdminInsuranceEndorsementNew}
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/endorsements/:id/edit"
      component={AdminInsuranceEndorsementEdit}
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/insurance_policies/:id/endorsements/:id/company_edit"
      component={AdminInsuranceEndorsementCompanyEdit}
    />

    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/contracts"
      component={AdminContractIndex}
      checkAccess="show_contracts_module"
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/contracts/new"
      component={AdminContractNew}
      checkAccess="show_contracts_module"
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/contracts/:id"
      component={AdminContractShow}
      checkAccess="show_contracts_module"
    />
    <AdminLoggedLayout
      exact
      adminStandard
      path="/admin/contracts/:id/edit"
      component={AdminContractEdit}
      checkAccess="show_contracts_module"
    />

    <AdminLoggedLayout exact adminLight path="/admin/blogs" component={AdminBlogIndex} />
    <AdminLoggedLayout exact adminLight path="/admin/blogs/new" component={AdminBlogNew} />
    <AdminLoggedLayout exact adminLight path="/admin/blogs/:id" component={AdminBlogShow} />
    <AdminLoggedLayout exact adminLight path="/admin/blogs/:id/edit" component={AdminBlogEdit} />

    <AdminLoggedLayout exact path="/admin/companies" component={AdminCompanyIndex} />
    <AdminLoggedLayout exact path="/admin/companies/new" component={AdminCompanyNew} />
    <AdminLoggedLayout exact path="/admin/companies/:id" component={AdminCompanyShow} />
    <AdminLoggedLayout exact path="/admin/companies/:id/edit" component={AdminCompanyEdit} />

    <AdminLoggedLayout exact path="/admin/communes" component={AdminCommuneIndex} />
    <AdminLoggedLayout exact path="/admin/communes/new" component={AdminCommuneNew} />
    <AdminLoggedLayout exact path="/admin/communes/:id/edit" component={AdminCommuneEdit} />

    <AdminLoggedLayout exact path="/admin/industries" component={AdminIndustryIndex} />
    <AdminLoggedLayout exact path="/admin/industries/new" component={AdminIndustryNew} />
    <AdminLoggedLayout exact path="/admin/industries/:id/edit" component={AdminIndustryEdit} />

    <AdminLoggedLayout exact path="/admin/insurance_categories" component={AdminInsuranceCategoryIndex} />
    <AdminLoggedLayout exact path="/admin/insurance_categories/new" component={AdminInsuranceCategoryNew} />
    <AdminLoggedLayout exact path="/admin/insurance_categories/:id/edit" component={AdminInsuranceCategoryEdit} />

    <AdminLoggedLayout exact path="/admin/insurance_companies" component={AdminInsuranceCompanyIndex} />
    <AdminLoggedLayout exact path="/admin/insurance_companies/:id/edit" component={AdminInsuranceCompanyEdit} />
    <AdminLoggedLayout exact path="/admin/insurance_companies/new" component={AdminInsuranceCompanyNew} />

    <AdminLoggedLayout exact path="/admin/insurance_company_logos" component={InsuranceCompanyLogoIndex} />
    <AdminLoggedLayout exact path="/admin/insurance_company_logos/new" component={InsuranceCompanyLogoNew} />
    <AdminLoggedLayout exact path="/admin/insurance_company_logos/:id/edit" component={InsuranceCompanyLogoEdit} />

    <AdminLoggedLayout adminStandard exact path="/admin/leads" component={AdminLeadIndex} />
    <AdminLoggedLayout adminStandard exact path="/admin/leads/new" component={AdminLeadNew} />
    <AdminLoggedLayout adminStandard exact path="/admin/leads/:id" component={AdminLeadShow} />
    <AdminLoggedLayout adminStandard exact path="/admin/leads/:id/edit" component={AdminLeadEdit} />
    <AdminLoggedLayout
      adminStandard
      exact
      path="/admin/leads/:id/request_quotation"
      component={AdminLeadRequestQuotation}
    />

    <AdminLoggedLayout exact path="/admin/payment_plans" component={AdminPaymentPlanIndex} />
    <AdminLoggedLayout exact path="/admin/payment_plans/:id/edit" component={AdminPaymentPlanEdit} />
    <AdminLoggedLayout exact path="/admin/payment_plans/new" component={AdminPaymentPlanNew} />

    <AdminLoggedLayout exact path="/admin/regions" component={AdminRegionIndex} />
    <AdminLoggedLayout exact path="/admin/regions/new" component={AdminRegionNew} />
    <AdminLoggedLayout exact path="/admin/regions/:id/edit" component={AdminRegionEdit} />

    <AdminLoggedLayout adminStandard exact path="/admin/renewals" component={AdminLeadRenewalIndex} />
    <AdminLoggedLayout adminStandard exact path="/admin/renewals/:id" component={AdminLeadRenewalShow} />
    <AdminLoggedLayout
      adminStandard
      exact
      path="/admin/renewals/:id/request_quotation"
      component={AdminLeadRequestQuotation}
    />

    <AdminLoggedLayout adminStandard exact path="/admin/premium_collections" component={AdminPremiumCollectionIndex} />
    <AdminLoggedLayout exact path="/admin/commission_collections" component={AdminCommissionCollectionIndex} />

    <AdminLoggedLayout adminStandard exact path="/admin/reports" component={AdminReportIndex} />
    <AdminLoggedLayout adminStandard exact path="/admin/bulk_upload" component={BulkUploadIndex} />

    <AdminLoggedLayout exact path="/admin/provinces" component={AdminProvinceIndex} />
    <AdminLoggedLayout exact path="/admin/provinces/new" component={AdminProvinceNew} />
    <AdminLoggedLayout exact path="/admin/provinces/:id/edit" component={AdminProvinceEdit} />

    <AdminLoggedLayout exact path="/admin/slides" component={AdminSlideIndex} />
    <AdminLoggedLayout exact path="/admin/slides/new" component={AdminSlideNew} />
    <AdminLoggedLayout exact path="/admin/slides/:id/edit" component={AdminSlideEdit} />

    <AdminLoggedLayout exact path="/admin/users" component={AdminUserIndex} />
    <AdminLoggedLayout exact path="/admin/users/new" component={AdminUserNew} />
    <AdminLoggedLayout exact path="/admin/users/:id/edit" component={AdminUserEdit} />

    <AdminLoggedLayout exact path="/admin/settings" component={AdminSettingIndex} />
    <AdminLoggedLayout exact adminLight path="/admin/broker_landing_settings" component={AdminBrokerLandingSettings} />
    <AdminLoggedLayout exact adminLight path="/admin/client_landing_settings" component={AdminClientLandingSettings} />
    <AdminLoggedLayout adminStandard exact path="/admin/agenda" component={AdminMyAgendaIndex} />
    <AdminLoggedLayout adminStandard exact path="/admin/agenda/all" component={AdminAllTasksIndex} />

    {/* NonLogged screens */}
    <NonLoggedLayout exact path="/login" component={LoginIndex} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPasswordIndex} />
    <NonLoggedLayout exact path="/recover_password" component={RecoverPasswordIndex} />
    <NonLoggedLayout exact path="/insured/login" component={InsuredLoginIndex} />

    {/* Landing screens */}
    <LandingLayout exact path="/landing" component={MultiLanding} />
    <LandingLayout exact path="/" component={MultiLanding} />

    <LandingLayout exact path="/partner" component={PartnerLanding} />
    <LandingLayout exact path="/partner/como" component={PartnerHowDoesItWork} />
    <LandingLayout exact path="/partner/blog" component={PartnerBlog} />
    <LandingLayout exact path="/partner/blog/:id" component={PartnerBlogShow} />
    <LandingLayout exact path="/partner/landing_contact" component={PartnerContact} />
    <LandingLayout exact path="/partner/demo_contact" component={PartnerDemoContact} />

    <LandingLayout exact path="/corredor" component={BrokerLanding} />
    <LandingLayout exact path="/corredor/planes" component={BrokerPlans} />
    <LandingLayout exact path="/corredor/blog" component={BrokerBlog} />
    <LandingLayout exact path="/corredor/blog/:id" component={BrokerBlogShow} />
    <LandingLayout exact path="/corredor/landing_contact" component={BrokerContact} />
    <LandingLayout exact path="/corredor/demo_contact" component={BrokerDemoContact} />

    <LandingLayout exact path="/asegurado" component={ClientLanding} />
    <LandingLayout exact path="/asegurado/personas" component={InsuredPersons} />
    <LandingLayout exact path="/asegurado/companias" component={InsuredCompanies} />
    <LandingLayout exact path="/asegurado/blog" component={InsuredBlog} />
    <LandingLayout exact path="/asegurado/blog/:id" component={InsuredBlogShow} />
    <LandingLayout exact path="/asegurado/landing_contact" component={InsuredContact} />

    {/* Public screens */}
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/terminos" component={HomeTerms} />
    <PublicLayout exact path="/terms-service" component={HomeTermsServices} />
    <PublicLayout exact path="/approve_quotation/:id" component={LeadQuotationApproval} />
    <PublicLayout exact path="/l/:companyName/:companyId/:insuranceBrokerId" component={CompanyLanding} />
    <PublicLayout exact path="/l/oticcchc" component={OticLanding} />

    {/* 404 */}
    <Route component={NotFound} />
  </Switch>
);

export default routes;
