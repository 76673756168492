const generatePrompt = ({ selectedQuotations, additionalInstruction, compareAiPrompt }) => {
  const companyNames = selectedQuotations.map(item => item.insuranceCompanyName);

  return `
  Analiza y compara las siguientes cotizaciones de pólizas. 

  - Compara las cotizaciones adjuntas y completa cada sección con los detalles específicos que encuentres en los documentos cargados.
  - Cada sección tiene variables.
  - Si el resultado de una de las variables es "incluido", llenar el campo con un "Sí" y entre paréntesis informar el monto.
  - Si el resultado de una de las variables es "no especificado" o no está incluido, llenar el campo con un "No".
  - Si el resultado de una de las variables no está especificado, llenar el campo con un "No".
  - En la sección "Total", informa la prima bruta.
  - No incluir referencias.
  - Muestra todas las comparaciones de cada sección.
  - Entregar todo el resultado de una vez.
  - Entrega el resultado en formato JSON, con la siguiente estructura:
    [
        {
          title: "Nombre sección 1"
          result: [
            { "Variable": "Nombre variable 1", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} },
            { "Variable": "Nombre variable 2", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} }
            // ... Siguientes variables
          ],
        },
        {
          title: "Nombre sección 2"
          result: [
            { "Variable": "Nombre variable 1", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} },
            { "Variable": "Nombre variable 2", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} }
            // ... Siguientes variables
          ],
        },
        {
          title: "Nombre sección 3"
          result: [
            { "Variable": "Nombre variable 1", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} },
            { "Variable": "Nombre variable 2", ${companyNames
              .map(name => `"${name}": "...Resultado de la comparación"`)
              .join(', ')} }
            // ... Siguientes variables
          ],
        }
        // ... Siguientes secciones    
    ]
  - Retorna sólo el JSON.

  ${additionalInstruction && `Instrucción adicional: ${additionalInstruction}`}  

  Las secciones son:

  ${compareAiPrompt}

  Sección 1: Coberturas
  - Sismo
  - Cristales
  - RC Familiar
  - Accidentes personales
  - Viento, inundación y desbordamiento de cauces
  - Rotura cañerías
  - Daños por vehículos motorizados
  - Combustión espontánea
  - Erupción volcánica
  - Fenómenos de la naturaleza, excepto sismo
  - Peso de nieve o hielo
  - Aeronaves
  - Avalancha, aluviones y deslizamientos
  - Salida de mar
  - Explosión
  - Colapso edificio
  - Huelga, desorden popular
  - Terrorismo
  - Asistencia
  - Demolición edificio colindante

  Sección 2: Sublímites indemnización
  - Remoción escombros 
  - Pérdida entradas por arriendo
  - Inhabitabilidad vivienda
  - Daño eléctrico
  - Honorarios profesionales
  - Gastos permiso reconstrucción
  - Gastos combate incendio
  - Rejas, portones, jardines
  - Rotura flexibles

  Sección 3: Deducibles
  - Sismo
  - Viento, inundación
  - Daño eléctrico
  - Cristales
  - Rotura cañerías
  - Huelga, saqueo, desorden popular
  - Terrorismo
  - Pérdida entradas por arriendo
  - Incendio, explosión
  - Resp. Civil
  - Otros riesgos

  Sección 4: Total
  - Total (Costo total de cada cotización)
  `;
};

// eslint-disable-next-line import/prefer-default-export
export { generatePrompt };
