import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import moment from 'moment';

import { FormikInputRadio, FormikNumberFormat, FormikDatePicker } from '../../../components';
import {
  ItemNestedAttributes,
  InsurancePolicyCommission,
  PaymentPlanRemoteSelector
} from '../../../components/InsurancePolicies';
import { useDebouncedValue, useDidMountEffect } from '../../../hooks';
import { showAdminCommissionsRequest } from '../../../requests/admin/adminUsers';
import { showCommissionsRequest } from '../../../requests/user';
import {
  camelCaseEmptyStringRecursive,
  isEmptyAttribute,
  percentageLimit,
  scrollToTop,
  setFieldAttribute,
  addOneYear
} from '../../../services/utils';
import { paymentMethodOptions, renewalTypeOptions } from './insurancePoliciesFormOptions';
import CadencySelect from './CadenceSelect';
import CoverSection from '../../../components/Shared/CoverSection';
import { showInsuredCommissionsRequest } from '../../../requests/insured/insuredUsers';

const InsurancePolicyFormSecondTab = ({
  action,
  fromAdmin,
  modelName,
  fromLeadProposal,
  isProposal,
  leadPresent,
  userModule,
  isRenewal,
  useAi
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { settings } = useSelector(state => state.utils);

  const [user, setUser] = useState({});
  const dispatch = useDispatch();

  const {
    affectCommissionPercentage,
    affectPremium,
    brokerCommissionPercentage,
    exemptCommissionPercentage,
    exemptPremium,
    insuranceBrokerId,
    insuranceCompanyId,
    nicoCommissionPercentage,
    currency,
    validityStart
  } = values.insurancePolicy;

  const debouncedAffectCommissionPct = useDebouncedValue(affectCommissionPercentage);
  const debouncedAffectPremium = useDebouncedValue(affectPremium);
  const debouncedBrokerCommissionPct = useDebouncedValue(brokerCommissionPercentage);
  const debouncedExemptCommissionPct = useDebouncedValue(exemptCommissionPercentage);
  const debouncedExemptPremium = useDebouncedValue(exemptPremium);
  const debouncedNicoCommissionPct = useDebouncedValue(nicoCommissionPercentage);

  const calculateTotalPremium = () => {
    const taxPercentage = settings.iva ? settings.iva / 100 + 1 : 1;
    const calculatedTotalPremium = parseFloat(affectPremium || 0) * taxPercentage + parseFloat(exemptPremium || 0);
    setFieldAttribute(modelName, 'totalPremium', setFieldValue, calculatedTotalPremium, ` ${currency}`);
  };

  const calculateTotalNetPremium = () => {
    const calculatedTotalNetPremium = parseFloat(affectPremium || 0) + parseFloat(exemptPremium || 0);
    setFieldAttribute(modelName, 'totalNetPremium', setFieldValue, calculatedTotalNetPremium, ` ${currency}`);
  };

  const calculateCommissions = () => {
    if (isEmptyAttribute(affectCommissionPercentage) && isEmptyAttribute(exemptCommissionPercentage)) return;

    const affectCommissionAmount = (parseFloat(affectPremium || 0) * affectCommissionPercentage) / 100;
    const exemptCommissionAmount = (parseFloat(exemptPremium || 0) * exemptCommissionPercentage) / 100;
    const calculatedTotalCommissionAmount = affectCommissionAmount + exemptCommissionAmount;
    setFieldAttribute(
      modelName,
      'commissionTotalAmount',
      setFieldValue,
      calculatedTotalCommissionAmount,
      ` ${currency}`
    );

    if (isEmptyAttribute(brokerCommissionPercentage) || isEmptyAttribute(nicoCommissionPercentage)) return;

    const internalCommissionAmount = calculatedTotalCommissionAmount * (1 - nicoCommissionPercentage / 100);
    const totalBrokerCommissionAmount = internalCommissionAmount * (brokerCommissionPercentage / 100);
    setFieldAttribute(modelName, 'brokerCommissionAmount', setFieldValue, totalBrokerCommissionAmount, ` ${currency}`);
  };

  const handleInitialCommissionPercentage = () => {
    if (Object.keys(user).length === 0) return;
    setFieldAttribute(modelName, 'brokerCommissionPercentage', setFieldValue, user.brokerCommissionPercentage, '%');
    setFieldAttribute(modelName, 'nicoCommissionPercentage', setFieldValue, user.nicoCommissionPercentage, '%');
  };

  const getShowCommissionRequest = () => {
    const requestMap = {
      admin: showAdminCommissionsRequest,
      broker: showCommissionsRequest,
      insured: showInsuredCommissionsRequest
    };
    return requestMap[userModule];
  };

  const canSendParams = () => {
    return fromAdmin || userModule === 'insured';
  };

  const fetchCurrentUserCommissions = () => {
    if (action !== 'new' || fromLeadProposal) return;
    const showCommissionRequest = getShowCommissionRequest();
    const sendParams = canSendParams()
      ? { insurance_broker_id: insuranceBrokerId, insurance_company_id: insuranceCompanyId }
      : {};
    if (canSendParams() && !insuranceBrokerId) return;

    showCommissionRequest({
      dispatch,
      params: sendParams,
      successCallback: response => setUser(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const updateValidityEnd = () => {
    const newValidityEnd = new Date(moment(validityStart, 'DD-MM-YYYY'));
    setFieldValue(`${modelName}[validityEnd]`, addOneYear(newValidityEnd));
  };

  const showCommissionFields = () => {
    return userModule !== 'insured';
  };

  useEffect(scrollToTop, []);
  useDidMountEffect(updateValidityEnd, [validityStart]);
  useEffect(fetchCurrentUserCommissions, [insuranceBrokerId]);
  useDidMountEffect(handleInitialCommissionPercentage, [
    user.brokerCommissionPercentage,
    user.nicoCommissionPercentage
  ]);
  useEffect(calculateTotalPremium, [debouncedAffectPremium, debouncedExemptPremium, settings.iva]);
  useEffect(calculateTotalNetPremium, [debouncedAffectPremium, debouncedExemptPremium]);
  useDidMountEffect(calculateCommissions, [
    debouncedAffectCommissionPct,
    debouncedAffectPremium,
    debouncedBrokerCommissionPct,
    debouncedExemptCommissionPct,
    debouncedExemptPremium,
    debouncedNicoCommissionPct
  ]);

  return (
    <>
      <section className="form-section mb-5 w-100">
        {/* <CoverSection fromAdmin={fromAdmin} modelName={modelName} disabled={isProposal} /> */}
        <CoverSection
          fromAdmin={fromAdmin}
          modelName={modelName}
          disabled={(isProposal && leadPresent) || fromLeadProposal}
        />
      </section>

      <section className="form-section mb-5 w-100">
        <p className="section-title">Información de la Materia a asegurar</p>
        <ItemNestedAttributes
          modelName={modelName}
          action={action}
          fromLeadProposal={fromLeadProposal}
          isProposal={isProposal}
          leadPresent={leadPresent}
          isRenewal={isRenewal}
          useAi={useAi}
        />
      </section>

      <hr className="w-100 mb-5" />

      {console.log('Prima afecta total: ', getIn(values, `${modelName}[affectPremium]`))}
      <section className="form-section mb-5 w-100">
        <p className="section-title">Total póliza</p>
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[parsedAffectPremium]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  abbr
                  label="Prima afecta total"
                  suffix={` ${currency}`}
                  defaultValue={getIn(values, `${modelName}[affectPremium]`)}
                  fieldName={`${modelName}[affectPremium]`}
                  error={getIn(errors, `${modelName}[affectPremium]`)}
                  touched={getIn(touched, `${modelName}[affectPremium]`)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[parsedExemptPremium]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  abbr
                  label="Prima exenta total"
                  suffix={` ${currency}`}
                  defaultValue={getIn(values, `${modelName}[exemptPremium]`)}
                  fieldName={`${modelName}[exemptPremium]`}
                  error={getIn(errors, `${modelName}[exemptPremium]`)}
                  touched={getIn(touched, `${modelName}[exemptPremium]`)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[parsedTotalNetPremium]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  abbr
                  disabled
                  label="Prima total neta"
                  suffix={` ${currency}`}
                  placeholder="Cálculo prima total neta"
                  value={getIn(values, `${modelName}[totalNetPremium]`)}
                  tooltipText={<TotalNetPremiumTooltip />}
                  tooltipHtml={false}
                  error={getIn(errors, `${modelName}[totalNetPremium]`)}
                  touched={getIn(touched, `${modelName}[totalNetPremium]`)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[parsedGrossInstallment]`}>
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  optional
                  label="Valor cuota bruto"
                  suffix={` ${currency}`}
                  defaultValue={getIn(values, `${modelName}[grossInstallment]`)}
                  fieldName={`${modelName}[grossInstallment]`}
                  error={getIn(errors, `${modelName}[grossInstallment]`)}
                  touched={getIn(touched, `${modelName}[grossInstallment]`)}
                />
              )}
            </Field>
          </Col>

          {showCommissionFields() && (
            <>
              <Col md={6} lg={4} xl={3}>
                <Field name={`${modelName}[parsedAffectCommissionPercentage]`}>
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      abbr
                      label="% Comisión póliza afecta"
                      suffix=" %"
                      isAllowed={percentageLimit}
                      defaultValue={getIn(values, `${modelName}[affectCommissionPercentage]`)}
                      fieldName={`${modelName}[affectCommissionPercentage]`}
                      error={getIn(errors, `${modelName}[affectCommissionPercentage]`)}
                      touched={getIn(touched, `${modelName}[affectCommissionPercentage]`)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Field name={`${modelName}[parsedExemptCommissionPercentage]`}>
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      abbr
                      label="% Comisión póliza exenta"
                      suffix=" %"
                      isAllowed={percentageLimit}
                      defaultValue={getIn(values, `${modelName}[exemptCommissionPercentage]`)}
                      fieldName={`${modelName}[exemptCommissionPercentage]`}
                      error={getIn(errors, `${modelName}[exemptCommissionPercentage]`)}
                      touched={getIn(touched, `${modelName}[exemptCommissionPercentage]`)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Field name={`${modelName}[parsedCommissionTotalAmount]`}>
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      abbr
                      disabled
                      label="Comisión total póliza neta"
                      placeholder="Cálculo comisión póliza neta"
                      suffix={` ${currency}`}
                      value={getIn(values, `${modelName}[commissionTotalAmount]`)}
                      error={getIn(errors, `${modelName}[commissionTotalAmount]`)}
                      touched={getIn(touched, `${modelName}[commissionTotalAmount]`)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}

          <Col lg={4} md={6} xl={3}>
            <Field name={`${modelName}[cadenceType]`}>
              {({ field }) => <CadencySelect field={field} modelName={modelName} />}
            </Field>
          </Col>

          {showCommissionFields() && <AdminBrokerCommissionFields fromAdmin={fromAdmin} modelName={modelName} />}
        </Row>
      </section>

      <hr className="w-100 mb-5" />

      <section className="form-section mb-5 w-100">
        <p className="section-title">Vigencia</p>
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[validityStart]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Inicio"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[validityEnd]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Término"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[issueDate]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Fecha de emisión"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[firstPaymentDate]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  optional
                  label="Fecha primer pago"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col lg={9}>
            <Field name={`${modelName}[renewalType]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  field={field}
                  label="Tipo de renovación"
                  options={renewalTypeOptions}
                  fieldName={`${modelName}[translatedRenewalType]`}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>

      <hr className="w-100 mb-5" />

      <section className="form-section mb-5 w-100">
        <p className="section-title">Pago</p>
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[paymentPlanId]`}>
              {({ field }) => (
                <PaymentPlanRemoteSelector
                  values={values}
                  errors={errors}
                  touched={touched}
                  field={field}
                  modelName={modelName}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name={`${modelName}[paymentMethod]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  field={field}
                  label="Forma de pago"
                  options={paymentMethodOptions}
                  fieldName={`${modelName}[translatedPaymentMethod]`}
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>
    </>
  );
};

const AdminBrokerCommissionFields = ({ fromAdmin, modelName }) => {
  const { errors, touched, values } = useFormikContext();
  const { currency } = values.insurancePolicy;

  return fromAdmin ? (
    <Col xs={12}>
      <Row>
        <Col md={6} lg={4} xl={3}>
          <Field name={`${modelName}[parsedNicoCommissionPercentage]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="% Comisión NICO"
                suffix=" %"
                isAllowed={percentageLimit}
                defaultValue={getIn(values, `${modelName}[nicoCommissionPercentage]`)}
                fieldName={`${modelName}[nicoCommissionPercentage]`}
                error={getIn(errors, `${modelName}[nicoCommissionPercentage]`)}
                touched={getIn(touched, `${modelName}[nicoCommissionPercentage]`)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <Field name={`${modelName}[parsedBrokerCommissionPercentage]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="% Sub-comisión corredor"
                suffix=" %"
                isAllowed={percentageLimit}
                defaultValue={getIn(values, `${modelName}[brokerCommissionPercentage]`)}
                fieldName={`${modelName}[brokerCommissionPercentage]`}
                error={getIn(errors, `${modelName}[brokerCommissionPercentage]`)}
                touched={getIn(touched, `${modelName}[brokerCommissionPercentage]`)}
              />
            )}
          </Field>
        </Col>

        <BrokerCommissionAmountField modelName={modelName} values={values} currency={currency} />
      </Row>
    </Col>
  ) : (
    <BrokerCommissionAmountField modelName={modelName} values={values} currency={currency} />
  );
};

const BrokerCommissionAmountField = ({ modelName, values, currency }) => (
  <Col md={6} lg={4} xl={3}>
    <Field name={`${modelName}[parsedBrokerCommissionAmount]`}>
      {({ field }) => (
        <FormikNumberFormat
          {...field}
          abbr
          disabled
          label="Comisión corredor"
          placeholder="Cálculo comisión corredor"
          suffix={` ${currency}`}
          value={getIn(values, `${modelName}[brokerCommissionAmount]`)}
          tooltipText={<BrokerCommissionAmountTooltip withTitle values={values} currency={currency} />}
          tooltipHtml={false}
        />
      )}
    </Field>
  </Col>
);

const BrokerCommissionAmountTooltip = ({ values, withTitle, currency }) => {
  const {
    brokerCommissionAmount,
    brokerCommissionPercentage,
    commissionTotalAmount,
    nicoCommissionPercentage
  } = values.insurancePolicy;

  const companyCommissionPercentage = 100 - brokerCommissionPercentage;
  const baseCommissionPercentage = 100 - nicoCommissionPercentage;
  const nicoCommissionAmount = commissionTotalAmount * (nicoCommissionPercentage / 100);
  const companyCommissionAmount = commissionTotalAmount - nicoCommissionAmount - brokerCommissionAmount;

  return (
    <>
      {withTitle && (
        <>
          <p>Esta es la comisión total que recibirás por esta póliza.</p>
          <p className="mb-3">Todos los descuentos ya fueron aplicados.</p>
        </>
      )}
      <InsurancePolicyCommission
        basePercentage={`${baseCommissionPercentage || 0}%`}
        brokerAmount={`${Number(brokerCommissionAmount).toFixed(2)} ${currency}`}
        brokerPercentage={`${brokerCommissionPercentage || 0}%`}
        companyAmount={`${Number(companyCommissionAmount).toFixed(2)} ${currency}`}
        companyPercentage={`${companyCommissionPercentage || 0}%`}
        nicoAmount={`${Number(nicoCommissionAmount).toFixed(2)} ${currency}`}
        nicoPercentage={`${nicoCommissionPercentage || 0}%`}
        totalAmount={`${Number(commissionTotalAmount).toFixed(2)} ${currency}`}
      />
    </>
  );
};

const TotalNetPremiumTooltip = () => {
  return (
    <>
      <p>Corresponde a la suma de la prima afecta (sin IVA) y la prima exenta.</p>
      <p>
        Si la póliza es de <b>cadencia única</b>, corresponde a la prima a pagar por la vigencia total de la póliza.
      </p>
      <p>
        Si es de <b>cadencia mensual</b>, corresponde a la prima a pagar mensualmente.
      </p>
      <p>Esta última puede ser referencial ya que el valor podría variar todos los meses.</p>
    </>
  );
};

export default InsurancePolicyFormSecondTab;
