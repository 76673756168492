import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import logoWhite from '../../Utils/MainLogo/logos/logo_white.png';
import { IconBtn } from '../../Utils/Button';

const CompanyLandingNavbarMultiLogo = ({ logoInfo = [] }) => {
  return (
    <div className="d-flex align-items-center">
      <Image fluid src={logoWhite} alt="Logo" className="company-landing__navbar-logo" />
      {logoInfo.map(logo => (
        <React.Fragment key={logo?.key || logo?.id}>
          <div className="mx-3">
            <IconBtn noBtn onlyIcon icon="add-filled" iconSize="sm" variant={logo?.preIconVariant || 'white'} />
          </div>
          <Image
            fluid
            src={logo?.fileUrl}
            alt="Logo"
            className={`company-landing__navbar-logo ${logo?.className || ''}`}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

CompanyLandingNavbarMultiLogo.propTypes = {
  logoInfo: PropTypes.arrayOf(
    PropTypes.shape({
      fileUrl: PropTypes.string
    })
  ).isRequired
};

export default CompanyLandingNavbarMultiLogo;
