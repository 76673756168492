import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminContractItemRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/contract_items', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminContractItemRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/contract_items/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const showAdminContractItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/contract_items/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminContractItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/contract_items/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const adminBuklUploadContractItemRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', `/admin/contract_items/bulk_upload`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminContractItemRequest = AwesomeDebouncePromise(indexAdminContractItemRequest, 1000);

export const debounceUpdateAdminContractItemRequest = AwesomeDebouncePromise(updateAdminContractItemRequest, 1000);
