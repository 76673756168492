import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { camelCaseEmptyStringRecursive, redirectTo } from '../../../services/utils';
import ContractForm from '../../../components/Contract/ContractForm/ContractForm';
import {
  showAdminInsuranceContractRequest,
  updateAdminInsuranceContractRequest
} from '../../../requests/admin/adminInsuranceContracts';
import adminBasicContract from './adminBasicContract';

const AdminContractEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/contracts';

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { contractItemId } = !!location.state && location.state;

  const [insuranceContract, setInsuranceContract] = useState(adminBasicContract);

  const handleBreadcrumb = [
    { key: 1, name: 'Contratos', href: modelUrl },
    { key: 2, name: 'Detalle de bien', href: `${modelUrl}/${contractItemId}` },
    { key: 3, name: 'Editar contrato' }
  ];

  const fetchInsuranceContract = () => {
    showAdminInsuranceContractRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: response => {
        const data = camelCaseEmptyStringRecursive(response.data);
        data.disableEndDate = !data.validityEnd;
        setInsuranceContract(data);
      }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Contrato actualizado con éxito' }));
    redirectTo(history, `${modelUrl}`);
  };

  const handleParams = ({ contract, ...currentInsuranceContract }) => {
    let sendParams = snakeCaseKeys(currentInsuranceContract, { exclude: ['_destroy'] });
    sendParams = { ...sendParams, contract };

    return sendParams;
  };

  const handleUpdateInsuranceContractRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insuranceContract);

    updateAdminInsuranceContractRequest(id, {
      dispatch,
      params: { insurance_contract: sendParams },
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => {
        setSubmitting(false);
      }
    });
  };

  useEffect(fetchInsuranceContract, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <ContractForm
          userModule="admin"
          fromAdmin
          action="edit"
          modelUrl={modelUrl}
          insuranceContract={insuranceContract}
          formRequest={handleUpdateInsuranceContractRequest}
        />
      }
    />
  );
};

export default AdminContractEdit;
