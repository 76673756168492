import React from 'react';

import { excludeKeys } from '../../../services/utils';
import generalObject from './generalObject';
import { InfoCard } from '../../Utils/Cards';

const InfoCardGeneral = ({ contract, userModule, hideAttributes }) => {
  let generalInfo = generalObject({ contract, userModule });
  generalInfo = excludeKeys(generalInfo, hideAttributes);

  return <InfoCard title="Información del contrato" object={generalInfo} />;
};

export default InfoCardGeneral;
