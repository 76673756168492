import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import moment from 'moment';

import { BasicTextArea, FormikDatePicker, FormikInput } from '../../Utils/Input';
import { addOneYear, textUpperCase } from '../../../services/utils';
import StatusSelect from './StatusSelect';
import { BtnCheckbox } from '../../Utils/Button';
import AttachFile from '../../Shared/AttachFile';
import DropzoneFileSection from '../../Shared/DropzoneFileSection';
import { useDidMountEffect } from '../../../hooks';

const ContractInfoSection = ({ modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [temporalInvoiceFiles, setTemporalInvoiceFiles] = useState([]);
  const [temporalAdditionalFiles, setTemporalAdditionalFiles] = useState([]);

  const insuranceContract = getIn(values, modelName);
  const disableEndDate = getIn(values, `${modelName}[disableEndDate]`);
  const { additionalDocumentsInfo, invoicesInfo, validityStart } = insuranceContract;

  const contractNumberTooltip = () => {
    return `Ingresa el número de contrato, operación o registro que te permite identificar el contrato.`;
  };

  const executiveContractTooltip = () => {
    return `Ingresa el nombre del ejecutivo interno a cargo de la gestión del contrato.`;
  };

  const handleChecked = () => {
    setFieldValue(`${modelName}[disableEndDate]`, true);
    setFieldValue(`${modelName}[validityEnd]`, '');
  };

  const handleUnchecked = () => {
    setFieldValue(`${modelName}[disableEndDate]`, false);
    setFieldValue(`${modelName}[validityEnd]`, new Date());
  };

  const updateValidityEnd = () => {
    const newValidityEnd = new Date(moment(validityStart, 'DD-MM-YYYY'));
    setFieldValue(`${modelName}[validityEnd]`, addOneYear(newValidityEnd));
  };

  useDidMountEffect(updateValidityEnd, [validityStart]);

  return (
    <>
      <section className="form-section mb-5 w-100 account-section">
        <p className="section-title">Información del contrato</p>
        <Row>
          <Col md={6} lg={3}>
            <Field name={`${modelName}[contractNumber]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  placeholder="0000"
                  label="Número de contrato"
                  onChange={input => setFieldValue(field.name, textUpperCase(input.target.value))}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  tooltipText={contractNumberTooltip()}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={3}>
            <Field name={`${modelName}[status]`}>
              {({ field }) => <StatusSelect field={field} modelName={modelName} />}
            </Field>
          </Col>
          <Col md={6} lg={4}>
            <Field name={`${modelName}[executiveContract]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Ejecutivo contrato"
                  placeholder="Nombre completo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  tooltipText={executiveContractTooltip()}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field name={`${modelName}[description]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Descripción"
                  placeholder="Ingresa aquí una descripción que te permita entender a grandes rasgos el contenido del contrato."
                  rows={2}
                  className="p-3"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[signatureDate]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  label="Fecha de firma"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[validityStart]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Inicio vigencia"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <Field name={`${modelName}[validityEnd]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr={!disableEndDate}
                  label="Término vigencia"
                  placeholder="dd/mm/aaaa"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  disabled={disableEndDate}
                />
              )}
            </Field>
          </Col>
          <Col md={6} lg={4} xl={3} className="d-flex align-items-center">
            <BtnCheckbox
              label="Sin fecha de término"
              checked={disableEndDate}
              onClickChecked={handleChecked}
              onClickUnchecked={handleUnchecked}
            />
          </Col>
        </Row>
        <Row />
        <Row>
          <Col xs={12} md={12} lg={12}>
            <AttachFile label="Contrato" attribute="contract" modelName={modelName} fileAccept="application/pdf" />
          </Col>

          <Col xs={12} md={6} lg={6}>
            <DropzoneFileSection
              multiple
              label="Facturas"
              modelName={modelName}
              originalObject={insuranceContract}
              persistedFiles={invoicesInfo}
              addKeyName="addInvoiceDocumentKeys"
              removeKeyName="removeInvoiceAttachmentIds"
              temporalDropzoneFiles={temporalInvoiceFiles}
              setTemporalDropzoneFiles={setTemporalInvoiceFiles}
            />
          </Col>

          <Col xs={12} md={6} lg={6}>
            <DropzoneFileSection
              multiple
              label="Documentos adicionales"
              modelName={modelName}
              originalObject={insuranceContract}
              persistedFiles={additionalDocumentsInfo}
              temporalDropzoneFiles={temporalAdditionalFiles}
              setTemporalDropzoneFiles={setTemporalAdditionalFiles}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <Field name={`${modelName}[comments]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Comentarios"
                  placeholder="Ingresa un comentario..."
                  row={4}
                  className="p-3"
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>

      <hr className="w-100 mb-5" />
    </>
  );
};

export default ContractInfoSection;
