import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { createAdminPremiumCollectionRequest } from '../../../requests/admin/adminPremiumCollections';
import AdminPremiumCollectionForm from './AdminPremiumCollectionForm';

const basicPremiumCollection = {
  id: '',
  collectionDate: '',
  collectionType: 'premium',
  comments: '',
  createdFrom: 'monthly_cadence',
  installmentNumber: '',
  insurancePolicyId: '',
  status: '',
  totalNetPremiumCalculated: ''
};

const AdminPremiumCollectionNew = ({ handleModalClose, setModalShow, setMoreData }) => {
  const modelUrl = '/admin/premium_collections';
  const dispatch = useDispatch();

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    createAdminPremiumCollectionRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => dispatch(sendAlert({ kind: 'success', message: 'Prima creada con éxito' })),
      callback: () => {
        setSubmitting(false);
        setModalShow(false);
        setMoreData(m => !m);
      }
    });
  };

  return (
    <AdminPremiumCollectionForm
      modelUrl={modelUrl}
      premiumCollection={basicPremiumCollection}
      formRequest={handleCreateRequest}
      handleCancelButton={handleModalClose}
      submitTextBtn="Crear cobranza"
      formType="premium"
      userModule="admin"
    />
  );
};

export default AdminPremiumCollectionNew;
