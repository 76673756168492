import React, { useState } from 'react';

import { SimpleCenteredModal } from '../../Utils/Modal';
import { InfoCard } from '../../Utils/Cards';
import contractItemsObject from './contractItemsObject';
import ContractItemTable from './ContractItemTable';

const InfoCardContractItems = ({ contract, userModule }) => {
  const [itemsModalShow, setItemsModalShow] = useState(false);

  const contractInfo = contractItemsObject({
    contract,
    setItemsModalShow,
    userModule
  });

  return (
    <InfoCard title="Información de los bienes" object={contractInfo}>
      <SimpleCenteredModal
        size="xl"
        title="Bienes"
        show={itemsModalShow}
        body={<InsuranceItemTable contractItems={contract.contractItemsAttributes} />}
        onHide={() => setItemsModalShow(false)}
      />
    </InfoCard>
  );
};

const InsuranceItemTable = ({ contractItems }) => {
  const currentContractItems = contractItems.filter(insuranceItem => !insuranceItem._destroy);

  return <ContractItemTable contractItems={currentContractItems} />;
};

export default InfoCardContractItems;
