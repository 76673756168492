import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminInsuranceContractRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_contracts/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });
export const createAdminInsuranceContractRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/insurance_contracts', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminInsuranceContractRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/insurance_contracts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const debounceUpdateAdminInsuranceContractRequest = AwesomeDebouncePromise(
  updateAdminInsuranceContractRequest,
  1000
);
