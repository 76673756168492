import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { FormHeader } from '../../components';
import ContractForm from '../../components/Contract/ContractForm/ContractForm';
import basicContract from './basicContract';
import { sendAlert } from '../../actions/utils';
import { excludeKeys, redirectTo } from '../../services/utils';
import { createInsuranceContractRequest } from '../../requests/insuranceContracts';

const ContractNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modelUrl = '/contracts';

  const handleBreadcrumb = [
    { key: 1, name: 'Contratos', href: modelUrl },
    { key: 2, name: 'Crear nuevo contrato' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Contrato creado con éxito' }));

    redirectTo(history, `${modelUrl}`);
  };

  const handleParams = ({ contract, ...currentObject }) => {
    const contractKeys = ['contract'];
    let sendParams = snakeCaseKeys(currentObject);
    sendParams = { ...sendParams, contract };

    if (!contract) sendParams = snakeCaseKeys(excludeKeys(currentObject, contractKeys));
    return sendParams;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insuranceContract);

    createInsuranceContractRequest({
      dispatch,
      params: { insurance_contract: sendParams },
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => {
        setSubmitting(false);
      }
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <ContractForm
          userModule="broker"
          action="new"
          modelUrl={modelUrl}
          insuranceContract={basicContract}
          formRequest={handleCreateRequest}
        />
      }
    />
  );
};

export default ContractNew;
