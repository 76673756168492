import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { guidGenerator } from '../../../services/utils';

const BtnCheckbox = ({
  onClickChecked,
  onClickUnchecked,
  asSwitchCheckbox,
  margin,
  label = '',
  optional,
  customOnClick = null,
  checked = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnClick = () => {
    if (!isChecked) onClickChecked();
    if (isChecked) onClickUnchecked();
    setIsChecked(!isChecked);
  };
  const guid = guidGenerator();

  return (
    <Form.Group
      className={`d-flex align-items-center ${asSwitchCheckbox ? 'form-checkbox-switch' : 'form-checkbox'} ${margin &&
        margin}`}
    >
      <Form.Check readOnly id={`checkbox-btn-${guid}`} custom type="checkbox" checked={isChecked} {...props} />

      <Form.Label
        onClick={customOnClick || handleOnClick}
        htmlFor={`checkbox-btn-${label}`}
        className={`m-0 ${isChecked ? 'checked' : ''}`}
      >
        <p className="font-weight-500 m-0">
          {label} {optional && <span className="text-muted">(Opcional)</span>}
        </p>
      </Form.Label>
    </Form.Group>
  );
};

BtnCheckbox.propTypes = {
  onClickChecked: PropTypes.func,
  onClickUnchecked: PropTypes.func,
  asSwitchCheckbox: PropTypes.bool
};

BtnCheckbox.defaultProps = {
  onClickChecked: () => console.log('missing onClickChecked'),
  onClickUnchecked: () => console.log('missing onClickUnchecked'),
  asSwitchCheckbox: false
};

export default BtnCheckbox;
