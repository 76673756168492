/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, IconBtn } from '../../../components';

const columns = memoize(({ handleBasicActions, modelUrl, withEdit = false }) => [
  {
    name: 'Correo electrónico',
    selector: 'email',
    sortable: true,
    grow: '2'
  },
  {
    name: 'F. creación',
    selector: 'created_at',
    sortable: true,
    grow: '1',
    cell: ({ createdAt }) => createdAt
  },
  {
    name: 'Roles',
    selector: 'role',
    sortable: true,
    grow: '2',
    cell: ({ roles }) => roles.map(role => role.label).join(' / ')
  },
  {
    name: 'Empresa',
    selector: 'company',
    sortable: true,
    grow: '2',
    cell: ({ companyName }) => companyName || 'No aplica'
  },
  {
    name: 'Activo',
    selector: 'active',
    sortable: true,
    grow: '1',
    cell: ({ active }) => (
      <>
        {active ? (
          <IconBtn icon="check" iconSize="xs" variant="submit" noBtn />
        ) : (
          <IconBtn icon="close" iconSize="xs" variant="danger" noBtn />
        )}
      </>
    )
  },
  {
    cell: item => {
      const { active, signInCount, landingPath } = item;
      return (
        <BasicActionBtns
          item={item}
          path={modelUrl}
          clickHandler={handleBasicActions}
          withEdit={withEdit}
          withResend={signInCount === 0}
          resendText="Reenviar correo de bienvenida"
          withInactivateUser
          inactivateOptions={{
            variant: active ? 'danger' : 'success',
            icon: active ? 'user-remove' : 'user-add',
            tooltipText: active ? 'Desactivar usuario' : 'Activar usuario'
          }}
          withLandingPath={landingPath}
        />
      );
    },
    ignoreRowClick: true,
    allowOverflow: false,
    right: true,
    grow: '1',
    minWidth: '150px'
  }
]);

export default columns;
