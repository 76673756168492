import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconGG from '../Icon';

const IconBtn = ({
  children,
  className: customClassName,
  disabled,
  icon,
  iconClass = '',
  iconSize = 'md',
  noBtn,
  noIcon,
  onlyIcon,
  to,
  variant,
  newTab=false,
  ...props
}) => {
  return (
    <>
      {!children ? (
        <div className={`icon-container ${iconSize}`}>
          {onlyIcon ? (
            <div className={`only-icon ${customClassName}`}>
              {icon && <IconGG className={iconClass} icon={icon} size={iconSize} variant={variant} />}
            </div>
          ) : (
            <Button
              as={to ? Link : Button}
              to={to}
              className={`icon-btn ${customClassName} ${noBtn ? 'non-clickable' : ''} ${disabled ? 'disabled' : ''}`}
              variant={variant}
              {...props}
            >
              {icon && <IconGG className={`icon-btn--icon ${iconClass}`} icon={icon} size={iconSize} />}
            </Button>
          )}
        </div>
      ) : (
        <Button
          as={to ? Link : Button}
          to={to}
          target={newTab ? '_blank' : undefined}
          className={`icon-btn ${customClassName} ${noBtn ? 'non-clickable' : ''} ${disabled ? 'disabled' : ''}`}
          variant={variant}
          {...props}
        >
          {children}
          {icon && <IconGG className={`icon-btn--icon ${iconClass}`} icon={icon} size={iconSize} />}
        </Button>
      )}
    </>
  );
};

export default IconBtn;
