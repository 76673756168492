import React from 'react';
import { Image } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';
import { IconBtn } from '../../Utils/Button';

const CompanyLandingMain = ({ company }) => {
  const {
    landingBackgroundInfo,
    landingContentOne,
    landingContentOneTypewriter,
    landingContentTwo,
    landingPrimaryColor,
    logoInfo
  } = company;

  const renderLogo = () => {
    if (!Array.isArray(logoInfo)) {
      return <Image fluid src={logoInfo.fileUrl} alt="Logo" className="main-logo" width="100" height="auto" />;
    }
    return logoInfo.map((logo, index) => {
      if (index === 0) {
        return <Image fluid src={logo.fileUrl} alt="Logo" className="main-logo" width="100" height="auto" />;
      }
      return (
        <>
          <div className="mx-3">
            <IconBtn noBtn onlyIcon icon="add-filled" iconSize="sm" variant={logo?.preIconVariant || 'white'} />
          </div>
          <Image
            fluid
            src={logo.fileUrl}
            alt="Logo"
            className={`main-logo ${logo?.className}`}
            width="100"
            height="auto"
          />
        </>
      );
    });
  };

  return (
    <main
      id="main"
      className="company-landing__main"
      style={{
        '--inline-company-background': `url(${landingBackgroundInfo.fileUrl})`,
        '--inline-primary-color': landingPrimaryColor
      }}
    >
      <div className="landing--main__info w-75">
        <div className="d-flex justify-content-start align-items-center">{renderLogo()}</div>
        <p className="main-subtitle mt-5 mb-3">
          <span className="mr-2">{landingContentOne}</span>
          {landingContentOneTypewriter.length > 0 && (
            <span className="font-weight-bold">
              <Typewriter
                options={{
                  strings: landingContentOneTypewriter,
                  autoStart: true,
                  loop: true
                }}
              />
            </span>
          )}
        </p>
        <h1 className="main-title">{landingContentTwo}</h1>
      </div>
    </main>
  );
};

export default CompanyLandingMain;
