import { basicAddress } from '../../../components';

const basicUser = {
  id: '',
  active: true,
  email: '',
  showContractsModule: false,
  roleIds: [],
  insuranceBrokerAttributes: {
    id: '',
    address: '',
    addressAttributes: basicAddress,
    birthday: '',
    brokerCode: '',
    brokerCommission: '',
    brokerType: 'alfa',
    name: '',
    nationalIdentification: '',
    phone: ''
  }
};

export default basicUser;
