import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showInsuranceContractRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_contracts/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });
export const createInsuranceContractRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insurance_contracts', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateInsuranceContractRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insurance_contracts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const debounceUpdateInsuranceContractRequest = AwesomeDebouncePromise(updateInsuranceContractRequest, 1000);
