import React from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { NavbarTop, Sidebar } from '../../components';

const LoggedLayout = ({ component: Component, checkAccess, ...rest }) => {
  const hasAccess = (roles, brokerType, showModule) => {
    if (showModule !== undefined) {
      return roles.includes('broker') && brokerType && showModule;
    }

    return roles.includes('broker') && brokerType;
  };

  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        const user = payload && jwt(payload);
        const tagManagerArgs = {
          dataLayer: {
            userEmail: user?.email
          }
        };
        TagManager.dataLayer(tagManagerArgs);
        if (!payload) return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;

        const { roles, broker_type: brokerType } = user;

        if (!hasAccess(roles, brokerType, user[checkAccess]))
          return <div className="w-50 mx-auto text-center">Need to be a broker to access this route</div>;

        return (
          <>
            <NavbarTop userModule="broker" />
            <Sidebar userModule="broker" />
            <div className="content-body">
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
};

export default LoggedLayout;
