import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { DefaultHeader, SimpleCenteredModal } from '../../../components';
import { useFetchData } from '../../../hooks';
import {
  debounceHeaderDashboardAdminPremiumCollectionRequest,
  debounceIndexAdminPremiumCollectionRequest,
  exportAdminCommissionCollectionRequest
} from '../../../requests/admin/adminPremiumCollections';
import CommissionCollectionDataTable from '../../../components/CommissionCollection/CommissionCollectionDataTable';
import CommissionCollectionInfo from '../../../components/CommissionCollection/CommissionCollectionInfo';
import AdminBulkUploadCommissionNew from './AdminBulkUploadCommissionNew';
import { uploadTypes } from './bulkUploadTypes';
import { downloadFile, isUserAdmin } from '../../../services/utils';
import AdminCommissionCollectionNew from './AdminCommissionCollectionNew';
import { CommissionCollectionStatisticHeader } from '../../../components/CommissionCollection';

const AdminCommissionCollectionIndex = () => {
  const modelUrl = '/admin/commission_collections';
  const [modalShow, setModalShow] = useState(false);
  const [bulkUploadModalShow, setBulkUploadModalShow] = useState(false);
  const [uploadType, setUploadType] = useState(null);

  const [commissionModalShow, setCommissionModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useDispatch();

  const customParamsRef = useRef({});
  const { user: currentUser } = useSelector(state => state.auth);
  const isFullAdmin = isUserAdmin(currentUser) || currentUser.email === 'leonardo@nicoseguros.com';

  const {
    data: commissionCollections,
    totalData: totalCommissionCollections,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPremiumCollectionRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar cobranza primas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleBulkUploadModalClose = () => {
    setBulkUploadModalShow(false);
    setUploadType(null);
  };

  const handleExportCommissionCollectionRequest = ({ xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportAdminCommissionCollectionRequest({
      dispatch,
      params: snakecaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleModalClose = () => {
    setCommissionModalShow(false);
    setTimeout(() => {
      setModalTitle('');
      setModalBody('');
    }, 300);
  };

  const handleModalCreate = () => {
    setCommissionModalShow(true);
    setModalTitle('Crear comisión');
    setModalBody(
      <AdminCommissionCollectionNew
        setModalShow={setCommissionModalShow}
        setMoreData={setMoreData}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <DefaultHeader
      title="Cobranza comisiones"
      tooltipOnClick={() => setModalShow(true)}
      headerBtn
      headerBtnOptions={{
        icon: 'export',
        name: 'comisión informada',
        onClick: () => {
          setUploadType(uploadTypes.COMMISSION);
          setBulkUploadModalShow(true);
        }
      }}
      secondBtn
      secondBtnOptions={{
        icon: 'export',
        name: 'pago asociados',
        onClick: () => {
          setUploadType(uploadTypes.PAYMENT);
          setBulkUploadModalShow(true);
        }
      }}
      thirdBtn
      thirdBtnOptions={{
        isIconTooltip: true,
        variant: 'primary-dark',
        icon: 'software-download',
        name: 'Descargar reporte de comisiones',
        onClick: () =>
          handleExportCommissionCollectionRequest({
            xlsxName: 'reporte_de_comisiones'
          })
      }}
      fourthBtn
      fourthBtnOptions={{
        isIconTooltip: true,
        variant: 'primary-dark',
        icon: 'add',
        name: 'Nueva comisión',
        onClick: handleModalCreate
      }}
    >
      <CommissionCollectionStatisticHeader
        customParamsRef={customParamsRef}
        headerDashboardRequest={debounceHeaderDashboardAdminPremiumCollectionRequest}
        dataTableMoreData={moreData}
      />
      <CommissionCollectionDataTable
        userModule={isFullAdmin ? 'admin' : 'admin_standard'}
        tableData={commissionCollections}
        tableDataAmount={totalCommissionCollections}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
      />
      <SimpleCenteredModal
        size="md"
        show={modalShow}
        title="Cobranza comisiones"
        body={<CommissionCollectionInfo />}
        onHide={() => setModalShow(false)}
      />

      <AdminBulkUploadCommissionNew
        fromAdmin
        handleModalClose={handleBulkUploadModalClose}
        modalShow={bulkUploadModalShow}
        setMoreData={setMoreData}
        uploadType={uploadType}
      />
      <SimpleCenteredModal
        size="md"
        show={commissionModalShow}
        title={modalTitle}
        body={modalBody}
        onHide={handleModalClose}
      />
    </DefaultHeader>
  );
};

export default AdminCommissionCollectionIndex;
