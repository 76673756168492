/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, BtnCheckbox, DataTableBadges, DatatableMainInfo } from '../../components';
import { basicTask, DatatableTaskCreator } from '../../components/Task';
// import { DataTableBadges, DatatableMainInfo, ReadMore } from '..';

const columns = memoize(
  ({ setMoreData = () => null, modelUrl, handleBasicActions, selectedItems, handleCheckboxChange }) => [
    {
      name: 'N° contrato',
      selector: 'contract_number',
      sortable: true,
      grow: '1.5',
      minWidth: '150px',
      cell: ({ contractNumber, validityStart, validityEnd, rowStyle }) => (
        <DatatableMainInfo
          icon="minimize-alt"
          iconSize="sm"
          iconCustomClass="rotate-icon-45"
          btnIconStyle={rowStyle}
          title={contractNumber}
          subtitle={`I: ${validityStart}`}
          secondarySubtitle={`V: ${validityEnd}`}
        />
      )
    },
    {
      name: 'N° bien',
      selector: 'item_number',
      sortable: true,
      grow: '1.5',
      cell: ({ itemNumber }) => itemNumber
    },
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      grow: '1.5',
      cell: ({ name }) => name
    },
    {
      name: 'Cuenta',
      selector: 'account',
      sortable: true,
      grow: '2',
      cell: ({ accountName, accountNationalIdentification }) => (
        <DatatableMainInfo noIcon title={accountName} subtitle={accountNationalIdentification} />
      )
    },
    {
      name: 'N° póliza',
      selector: 'policy_number',
      sortable: true,
      grow: '1.5',
      minWidth: '150px',
      cell: ({ insurancePolicyInfo }) =>
        insurancePolicyInfo ? (
          <DatatableMainInfo
            noIcon
            title={insurancePolicyInfo?.policyNumber || '-'}
            subtitle={`I: ${insurancePolicyInfo?.validityStart}`}
            secondarySubtitle={`V: ${insurancePolicyInfo?.validityEnd}`}
          />
        ) : (
          '-'
        )
    },
    {
      name: 'Corredor',
      selector: 'insurance_broker_company_name',
      sortable: true,
      grow: '1.5',
      cell: ({ insuranceBrokerCompanyName }) => <DatatableMainInfo noIcon title={insuranceBrokerCompanyName} />
    },
    {
      name: 'Tareas',
      selector: 'tasks',
      sortable: false,
      grow: '1',
      minWidth: '130px',
      cell: ({ task = basicTask, id: contractItemId }) => (
        <DatatableTaskCreator
          shortBtn
          fromAdmin
          task={task}
          taskableType="ContractItem"
          taskableId={contractItemId}
          setMoreData={setMoreData}
        />
      )
    },
    {
      cell: row => <DataTableBadges row={row} />,
      sortable: false,
      right: true,
      grow: '1.5'
    },
    {
      cell: item => (
        <BtnCheckbox
          checked={selectedItems.includes(item.id)}
          onClickChecked={() => handleCheckboxChange(item.id)}
          onClickUnchecked={() => handleCheckboxChange(item.id)}
        />
      )
    },
    {
      cell: item => <BasicActionBtns item={item} clickHandler={handleBasicActions} path={modelUrl} withDestroy />,
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

export default columns;
