import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { SimpleCenteredModal } from '../../Utils/Modal';
import ModalBodyIcon from '../../Shared/ModalBodyIcon';

const SuccessRequestModal = ({ modalShow, setModalShow }) => {
  const handleClose = () => setModalShow(false);

  return (
    <SimpleCenteredModal
      show={modalShow}
      onHide={handleClose}
      body={
        <ModalBodyIcon
          icon="check-o"
          iconVariant="success"
          content="¡Certificado creado con "
          highlightedText="éxito!"
          highlightedTextVariant="success"
          subContent="Revisaremos el certificado para asegurarnos de que está todo en
orden. Con esto, emitiremos el certificado oficial con la compañía de seguros
y te lo enviaremos directamente a tu correo electrónico."
          confirmText={false}
          handleModalClose={handleClose}
          formContent={
            <Row className="justify-content-center">
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" onClick={handleClose}>
                  Entendido
                </Button>
              </Col>
            </Row>
          }
        />
      }
    />
  );
};

export default SuccessRequestModal;
