import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik, getIn } from 'formik';
import { StepMap } from '../../Shared';
import ContractFormFirstTab from './ContractFormFirstTab';
import { IconBtn } from '../../Utils/Button';
import { contractSchema, firstStepFields, secondStepFields } from './validationSchema';
import ContractFormSecondTab from './ContractFormSecondTab';
import ContractFormSummary from './ContractFormSummary';

const ContractForm = ({ userModule, action, modelUrl, isSubmitting, errors, setFieldTouched, ...props }) => {
  const { setSubmitting, handleSubmit } = props;
  const modelName = 'insuranceContract';
  const [step, setStep] = useState(1);

  const submitBtnText = action === 'new' ? 'Crear contrato' : 'Guardar Cambios';
  const updatedFirstStepFields = userModule === 'admin' ? [...firstStepFields, 'insuranceBrokerId'] : firstStepFields;

  const checkStep = stepFields => {
    const stepErrors = getIn(errors, modelName);
    if (!stepErrors) return true;
    const result = stepFields.filter(value => stepErrors[value]);
    return result.length === 0;
  };

  const fromAdmin = () => {
    return userModule === 'admin';
  };

  const checkFirstStep = () => {
    return checkStep(updatedFirstStepFields);
  };

  const checkSecondStep = () => {
    return checkStep(secondStepFields);
  };

  const firstStepTouch = () => {
    updatedFirstStepFields.forEach(field => setFieldTouched(`${modelName}[${field}]`, true));
  };

  const secondStepTouch = () => {};

  const validStep = stepToValidate => {
    const validations = { 1: checkFirstStep, 2: checkSecondStep };
    const showErrors = { 1: firstStepTouch, 2: secondStepTouch };
    const validation = validations[stepToValidate];
    if (validation) {
      if (!validation()) {
        const showError = showErrors[stepToValidate];
        showError();
        return false;
      }
    }
    return true;
  };

  const toggleStep = ({ back = false }) => {
    let steps = { 0: 1, 1: 2 };
    const limitStep = back ? 0 : 3;
    if (back) {
      steps = { 3: 2, 2: 1 };
      setStep(steps[step] || limitStep);
    }

    if (validStep(step)) {
      setStep(steps[step] || limitStep);
    }
  };

  const handleOnSubmit = () => {
    setSubmitting(true);
    handleSubmit();
  };

  return (
    <Form>
      <StepMap steps={[1, 2, 3]} currentStep={step} />

      {step === 1 && <ContractFormFirstTab fromAdmin={fromAdmin()} modelName={modelName} userModule={userModule} />}

      {step === 2 && <ContractFormSecondTab fromAdmin={fromAdmin()} modelName={modelName} userModule={userModule} />}

      {step === 3 && <ContractFormSummary action={action} modelName={modelName} />}

      <Row>
        <Col md={4} lg={3} className="mt-4">
          {step > 1 && (
            <Button block variant="cancel" onClick={() => toggleStep({ back: true })}>
              Volver
            </Button>
          )}
        </Col>
        <Col md={8} lg={9}>
          <Row className="d-flex flex-row-reverse align-items-center">
            <Col md={6} lg={4} className="mt-4">
              {step === 3 ? (
                <Button block variant="submit" onClick={handleOnSubmit} disabled={isSubmitting}>
                  {submitBtnText}
                </Button>
              ) : (
                <Button block variant="submit" onClick={toggleStep}>
                  Siguiente
                </Button>
              )}
            </Col>
            <Col md={5} lg={3} className="mt-4">
              <IconBtn block variant="cancel" to={modelUrl}>
                Cancelar
              </IconBtn>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
const setInitialValues = ({ insuranceContract }) => ({ insuranceContract });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema: contractSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new',
  validateOnChange: false
})(ContractForm);
