// eslint-disable-next-line import/prefer-default-export

export const insuranceContractFiltersParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filterWithPolicy: '',
  insuranceCompanyId: '',
  insuranceCategoryId: ''
};

export const insuranceContractTabsOptions = [
  { label: 'Con póliza', value: 'true' },
  { label: 'Sin póliza', value: 'false' },
  { label: 'Todos', value: '' }
];

export const statusOptions = [
  { label: 'Activo', value: 'active' },
  { label: 'Inactivo', value: 'inactive' }
];

export const insuranceContractOptions = [
  { label: 'Vigente', value: 'true' },
  { label: 'No Vigente', value: 'false' }
];

export const coverageOptions = [
  { label: 'Vigente', value: 'true' },
  { label: 'No Vigente', value: 'false' }
];

export const attachmentOptions = [
  { label: 'Pendiente', value: 'pending' },
  { label: 'Contrato', value: 'contract' },
  { label: 'Factura', value: 'invoice' },
  { label: 'Contrato + Factura', value: 'contract_invoice' }
];
