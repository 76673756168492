import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Image } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingYoungBroker from '../../../assets/images/Landing/BrokerLanding/young-broker.webp';
import BrokerLandingOlderBroker from '../../../assets/images/Landing/BrokerLanding/older-broker.webp';

const BrokerLandingKindServices = () => {
  const history = useHistory();

  const goToBrokerPlans = () => {
    history.push('/corredor/planes');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <section
      id="kindservices"
      className="broker-landing__horizontal-info__kindservices broker-color-bg-clients broker-landing__horizontal-info__kindservices_bgblue"
    >
      <h4 className="broker-landing__horizontal-info__title landing-section-title text-dark-blue">
        NICO es flexible y se adapta a tus necesidades
      </h4>
      <div className="col">
        <Row>
          <div className="col-md-6 col-sm-12 broker-landing__horizontal-info__kindservices__margin">
            <div className="broker-landing__horizontal-info__kindservices__card ">
              <Image
                className="broker-landing__horizontal-info__kindservices__card__img"
                src={BrokerLandingYoungBroker}
                alt="Joven corredora con ganas de hacer crecer su lista de clientes"
              />

              <div className="broker-landing__horizontal-info__kindservices__card__containertext">
                <h6 className="broker-landing__horizontal-info__kindservices__card__titlecard">
                  Buscas hacer crecer tu cartera y tus ingresos
                </h6>
                <p className="broker-landing__horizontal-info__kindservices__card__textcard">
                  NICO es tu aliado perfecto. Tú te enfocas en lo comercial y nosotros en lo operacional. Conviértete en
                  el/la mejor corredor/a y entrega un servicio excepcional a tus clientes.
                </p>
                <div className="broker-landing__horizontal-info__kindservices__card__btnleft">
                  <IconBtn
                    onClick={goToBrokerPlans}
                    className="main-button btn-landing w-mobile-100"
                    variant="secondary"
                  >
                    Ver planes
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="broker-landing__horizontal-info__kindservices__card ">
              <Image
                className="broker-landing__horizontal-info__kindservices__card__img"
                src={BrokerLandingOlderBroker}
                alt="Señor mayor con sus nietos aprovechando tiempos libres ya que nico lo ayuda con su carga laboral"
              />

              <div className="broker-landing__horizontal-info__kindservices__card__containertext">
                <h6 className="broker-landing__horizontal-info__kindservices__card__titlecard">
                  Buscas tener más tiempo para ti y mantener tus ingresos
                </h6>
                <p className="broker-landing__horizontal-info__kindservices__card__textcard">
                  NICO es tu aliado perfecto. Mantén los canales con tu cartera abiertos y nosotros hacemos todo.
                  Extiende la vida útil de tu cartera, genera ingresos por rentas y mantén protegidos a tus clientes.
                </p>
                <div className="broker-landing__horizontal-info__kindservices__card__btnleft">
                  <IconBtn
                    onClick={goToBrokerPlans}
                    className="main-button btn-landing w-mobile-100"
                    variant="secondary"
                  >
                    Ver planes
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default BrokerLandingKindServices;
