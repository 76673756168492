import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';
import { InfoBox } from '../../Shared';
import { BasicTextArea } from '../..';

const BulkUploadForm = ({ errors, setFieldValue, setSubmitting, isSubmitting, touched, textDescription, ...props }) => {
  const { errorMessage, handleModalClose, placeholder } = props;

  const handleCommissionColumnRules = ({
    rowArray: [nationalId, contractNumber, itemNumber, status, notes, policyNumber, executiveContract]
  }) => {
    let allStructure = '';

    if (nationalId) {
      const parsedNationalId = nationalId.trim();
      allStructure += `${parsedNationalId}`;
    }

    if (contractNumber) {
      const parsedContractNumber = contractNumber.trim();
      allStructure += `\t${parsedContractNumber}`;
    }

    if (itemNumber) {
      const parsedItemNumber = itemNumber.trim();
      allStructure += `\t${parsedItemNumber}`;
    }

    if (status) {
      const parsedStatus = status.trim();
      allStructure += `\t${parsedStatus}`;
    }

    if (notes) {
      const parsedNotes = notes.trim();
      allStructure += `\t${parsedNotes}`;
    }

    if (policyNumber) {
      const parsedPolicyNumber = policyNumber.trim();
      allStructure += `\t${parsedPolicyNumber}`;
    }

    if (executiveContract) {
      const parsedExecutiveContract = executiveContract.trim();
      allStructure += `\t${parsedExecutiveContract}`;
    }

    return allStructure;
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [] }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const formattedRows = handleCommissionColumnRules({ rowArray });

      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : formattedRows;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  return (
    <>
      {errorMessage && (
        <InfoBox icon="danger" variant="danger" text={`La carga no pudo ser procesada: ${errorMessage}`} />
      )}

      {textDescription}

      <Form>
        <Row>
          <Col xs={12}>
            <Field name="contractItem[contractItems]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row={6}
                  placeholder={placeholder}
                  onChange={({ target }) => {
                    const targetValue = target.value;
                    const rows = targetValue.split('\n');
                    handleOnChangeTextArea({ fieldName: field.name, rows });
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  className="p-3 textarea-tab-size"
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mt-2 d-flex justify-content-end">
          <Col md={7}>
            <Row className="d-flex justify-content-end">
              <Col md={3} className="mt-4">
                <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ premiumCollection }) => ({ premiumCollection });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

const validationSchema = Yup.object().shape({
  contractItem: Yup.object().shape({
    contractItems: Yup.string().required('Debes ingresar los datos de los bienes')
  })
});

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true
  // validateOnMount: props => props.action !== 'new'
})(BulkUploadForm);
