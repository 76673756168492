import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { PeopleSection } from '../../../components/Shared';
import AccountSection from '../../../components/InsurancePolicies/Forms/AccountSection';
import ReadPolicyModalAi from '../../../components/InsurancePolicies/ReadPolicyModalAi/ReadPolicyModalAi';
import { SimpleCenteredModal } from '../../../components';

const InsurancePolicyFormFirstTab = ({
  allowUpdate,
  setAllowUpdate,
  fromAdmin,
  isProposal,
  modelName,
  fromLeadProposal,
  userModule,
  setInsurancePolicy,
  setUseAi
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const showReadPolicyBtn = false;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const readAIButtonText = () => {
    return isProposal ? 'Leer cotización ' : 'Leer póliza ';
  };

  const handleReadPolicyModal = () => {
    setModalShow(true);
    setModalBody(
      <ReadPolicyModalAi
        handleModalClose={handleModalClose}
        setInsurancePolicy={setInsurancePolicy}
        modelName={modelName}
        isProposal={isProposal}
        setUseAi={setUseAi}
      />
    );
  };

  return (
    <>
      {showReadPolicyBtn && (
        <div className="d-flex justify-content-end mt-5">
          <Button className="mr-4" onClick={handleReadPolicyModal}>
            {readAIButtonText()}
            <span role="img" aria-label="sparkles">
              ✨
            </span>
          </Button>
        </div>
      )}

      <AccountSection
        allowUpdate={allowUpdate}
        setAllowUpdate={setAllowUpdate}
        fromAdmin={fromAdmin}
        modelName={modelName}
        isProposal={isProposal}
        fromLeadProposal={fromLeadProposal}
        userModule={userModule}
      />

      <PeopleSection modelName={modelName} userModule={userModule} />

      <SimpleCenteredModal size="sm" show={modalShow} body={modalBody} onHide={handleModalClose} />

      {/* <PeopleSection fromAdmin={fromAdmin} modelName={modelName} requiredValues={requiredValues} /> */}
    </>
  );
};

export default InsurancePolicyFormFirstTab;
