import React from 'react';
import { Image } from 'react-bootstrap';

import { IconBtn } from '../../Utils/Button';

const LandingCenteredContent = ({
  bgImage,
  bgVariant,
  btnStyle,
  btnText,
  btnVariant,
  logo,
  onClick,
  subtitle,
  text,
  title,
  to
}) => {
  const textColor = bgImage ? 'white' : 'black';

  return (
    <section className={`landing--centered-content-container ${bgVariant}`}>
      {bgImage && (
        <div className="landing--centered-content__bg-image">
          <Image src={bgImage} alt="landing-centered-content-1" />
        </div>
      )}
      <div className="landing--centered-content">
        <h3 className={`landing--centered-content__title text-color-${textColor} mb-md-3`}>{title}</h3>
        {logo && (
          <div className="landing--centered-content__logo">
            <Image src={logo} alt="landing-centered-content-2" />
          </div>
        )}
        {subtitle && <h4 className={`landing--centered-content__subtitle text-color-${textColor}`}>{subtitle}</h4>}
        {text && (
          <p
            className={`landing--centered-content__subtitle text-color-${textColor} row-title mb-5 text-color-${textColor}`}
          >
            {text}
          </p>
        )}
        {btnText && (
          <IconBtn
            to={to}
            onClick={onClick}
            variant={btnVariant}
            className={`rounded-border btn-landing ${btnStyle ? 'inline-color' : ''}`}
            style={btnStyle}
          >
            {btnText}
          </IconBtn>
        )}
      </div>
    </section>
  );
};

LandingCenteredContent.defaultProps = {
  bgImage: '',
  bgVariant: 'primary-var-light-grey',
  btnText: '',
  btnVariant: 'primary',
  logo: '',
  onClick: () => null,
  subtitle: '',
  title: '',
  to: ''
};

export default LandingCenteredContent;
