import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { withFormik, Form } from 'formik';

import DropzoneFileSection from './DropzoneFileSection';

const AdditionalDocumentForm = ({ isSubmitting, handleCancelButton, modelName, values, ...props }) => {
  const { addKeyName, removeKeyName, documentsInfo = [] } = props;
  const { additionalDocumentsInfo } = values[modelName];

  return (
    <Form>
      <DropzoneFileSection
        multiple
        label="Documentos adicionales"
        modelName={modelName}
        originalObject={values[modelName]}
        addKeyName={addKeyName}
        removeKeyName={removeKeyName}
        persistedFiles={additionalDocumentsInfo || documentsInfo}
      />

      <Row className="d-flex flex-row-reverse align-items-center">
        <Col sm={6} md={5} lg={4} xl={3} className="mt-4">
          <Button block type="submit" variant="submit" disabled={isSubmitting}>
            Guardar Cambios
          </Button>
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="mt-4">
          <Button block variant="cancel" className="mr-3" onClick={handleCancelButton}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ modelName, object }) => ({ [modelName]: object });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdditionalDocumentForm);
