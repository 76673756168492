// import infoCardElement from '../../../Utils/Cards/InfoCardMethods';

import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const contractItemsObject = ({ contract, setItemsModalShow }) => {
  const { contractItemsAttributes } = contract;

  const currentContractItems = contractItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);

  const object = {};

  if (currentContractItems.length > 0) {
    object.insuranceItems = infoCardElement({
      file: true,
      item: {
        title: `${currentContractItems.length} ${currentContractItems.length > 1 ? 'Ítems' : 'Ítem'}`,
        name: 'Ver detalle',
        onClick: () => setItemsModalShow(true)
      }
    });
  }

  return object;
};

export default contractItemsObject;
