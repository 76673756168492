const basicContractItem = {
  id: '',
  insuranceItemId: '',
  insurancePolicyId: '',
  itemNumber: '',
  name: '',
  notes: '',
  title: ''
};

export default basicContractItem;
