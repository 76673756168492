import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/insurance_policies', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showAdminInsurancePolicyRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_policies/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createAdminInsurancePolicyRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/insurance_policies', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminInsurancePolicyRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/insurance_policies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const deleteAdminInsurancePolicyRequest = (
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/insurance_policies/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteProposalAdminInsurancePolicyRequest = (id, { dispatch, successCallback, callback, source }) =>
  ApiService.request('delete', `/admin/insurance_policies/${id}/destroy_proposal`, {
    dispatch,
    successCallback,
    callback,
    source
  });

export const renewalTypeCounterAdminInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/insurance_policies/renewal_type_counter', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const exportInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/insurance_policies/${params.id}.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const nextProposalIndentification = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/insurance_policies/next_proposal_identification', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const indexAdminInsuranceItemRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_policies/${id}/insurance_items`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

  export const associateAdminContractItemsRequest = (
    id,
    { dispatch, params, formData, successCallback, failureCallback, callback, source }
  ) =>
    ApiService.request('put', `/admin/insurance_policies/${id}/associate_contract_items`, {
      dispatch,
      params,
      formData,
      successCallback,
      failureCallback,
      callback,
      showLeafArrayIndexes: true,
      source
    });

export const readAdminInsurancePolicyAiRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/insurance_policies/read_policy_ai', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAdminInsurancePoliciesRequest = AwesomeDebouncePromise(
  indexAdminInsurancePolicyRequest,
  1000
);

export const debounceUpdateAdminInsurancePolicyRequest = AwesomeDebouncePromise(
  updateAdminInsurancePolicyRequest,
  1000
);

export const debounceIndexAdminInsuranceItemRequest = AwesomeDebouncePromise(indexAdminInsuranceItemRequest, 1000);
