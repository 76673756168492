import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { IconBtn } from '../Utils/Button';

const ModalBodyIcon = ({ cancelBtnText, formContent, formRequest, handleModalClose, submitBtnText, ...props }) => {
  const {
    confirmContent,
    confirmText,
    content,
    highlightedText,
    highlightedVariant,
    icon,
    iconClass,
    iconVariant,
    isSubmitting,
    recipient,
    subContent,
    subContentClass,
    submitVariant,
    showIcon = true
  } = props;

  return (
    <>
      <Row className="modal-body-icon">
        {showIcon && (
          <Col xs={12} className="modal-body-icon__icon">
            <IconBtn noBtn variant={iconVariant} icon={icon} iconClass={iconClass} iconSize="xxl" />
          </Col>
        )}
        <Col xs={12} className="modal-body-icon__content">
          <h6>
            {content}
            <span className={`highlighted-text bg-${highlightedVariant || submitVariant}`}>{highlightedText}</span>
            {recipient && <span className="d-block">a {recipient}</span>}
          </h6>
        </Col>
        {subContent && (
          <Col xs={12} className={`modal-body-icon__sub-content ${subContentClass}`}>
            {subContent}
          </Col>
        )}
        {confirmText && (
          <Col xs={12} className="modal-body-icon__confirm-content">
            <p>{confirmContent}</p>
          </Col>
        )}
      </Row>

      {formContent || (
        <Row>
          <Col md={6} className="mt-4">
            <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
              {cancelBtnText}
            </Button>
          </Col>
          <Col md={6} className="mt-4">
            <Button
              block
              type="submit"
              variant={submitVariant}
              className="no-shadow"
              onClick={formRequest}
              disabled={isSubmitting}
            >
              {submitBtnText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

ModalBodyIcon.propTypes = {
  cancelBtnText: PropTypes.string,
  confirmContent: PropTypes.string,
  confirmText: PropTypes.bool,
  content: PropTypes.string,
  highlightedText: PropTypes.string,
  icon: PropTypes.string,
  iconVariant: PropTypes.string,
  subContentClass: PropTypes.string,
  submitBtnText: PropTypes.string,
  submitVariant: PropTypes.string
};

ModalBodyIcon.defaultProps = {
  cancelBtnText: 'Cancelar',
  confirmContent: '¿Estás seguro de querer hacerlo?',
  confirmText: true,
  content: 'Estás por',
  highlightedText: 'confirmar',
  icon: 'danger',
  iconVariant: 'warning',
  subContentClass: '',
  submitBtnText: 'Sí, seguro',
  submitVariant: 'submit'
};

export default ModalBodyIcon;
